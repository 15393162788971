<template>
  <div class="home">
    <HomeComponent msg='Welcome to AAVAA Labeler' />
  </div>
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line
import HomeComponent from '@/components/HomeComponent.vue';

export default {
  name: 'HomeView',
  components: {
    HomeComponent,
  },
};
</script>

<style scoped lang="scss">
#logo-img {
  width: 350px;
  margin: auto;
 }
</style>
