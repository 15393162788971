<template>
  <div>
    <NavBar
      :username="username"
      :token="token"
      :user="user"
      :role="role"
    />
    <SideBarICA
      @setselectedView="setselectedView"
      @choice-ica="choiceIca"
      @undo="Undo"
      @apply-ica="applyIca"
      @inverse-ica="inverseIca"
      @toggle-stims="toggleStims"
      @export="Export"
      @compare="Compare"
      ref="sidebar"
    />

    <div class="info">
      <div class="titltes-ica-container">
        <span class="titles-ica"> User ID | <b> {{user}} </b> </span>
        <span class="titles-ica"> Recording ID | <b> {{rec}} </b> </span>
        <span class="titles-ica"> Task | <b> {{task}} </b> </span>
      </div>
    </div>

    <div id="main" v-if="draw">
      <div class="signal" v-show="(selectedView === 'Signal')">
        <div class="signal-title">
          <div class="title">
            EEG Signal
          </div>
        </div>
        <div>
          <div
            v-for="(x, name, index) of this.request.channels"
            :key="index">
            <div>
              <contextSignal
                :timeseries="x"
                :stims="this.request.stims"
                :channelName="name"
                :sfreq="this.sfreq"
                :ref="name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="ica" v-show="(selectedView === 'ICs')">
        <div class="ica-title">
          <div class="title">
            Independent Components
          </div>
          <div class="checkbox-all-ics">
            <span class="checkbox-all">
              <label for="checkAll">
                All ICs
                <input
                  type='checkbox'
                  id="checkAll"
                  v-model='isCheckAll'
                  @click='checkAll()'>
              </label>
            </span>
          </div>
        </div>
        <div v-if="ready">
          <div
            v-for="(x, name, index) of ica"
            :key="index"
            id="ica-plot">
            <div>
              <contextICA
                :timeseries="x"
                :stims="this.request.stims"
                :channelName="name"
                :checkedICs="this.checkedICs"
                :annot="this.request.annotations_ica[name]"
                :sfreq="this.sfreq"
                :ref="name"
                @reset-last="resetLast"
                @draw-rect="drawRect"
                @delete-rect="deleteRect"
              />
            </div>
            <div class="check">
              <span class="check-span">
                <label :for='name'>
                  <input
                    type='checkbox'
                    :id='name'
                    v-model='checkedICs'
                    v-bind:value='name'
                    @change='updateCheckall()'>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import NavBar from '@/components/ica/NavBar.vue';
import SideBarICA from '@/components/ica/SideBar.vue';
import contextSignal from '@/components/ica/contextSignal.vue';
import contextICA from '@/components/ica/contextICA.vue';


export default {
    name: "mainICA",
    props : ['user', 'rec'],
    components: {
        NavBar,
        SideBarICA,
        contextSignal,
        contextICA,
    },
    data() {
        return {
            request: null,
            draw: false,
            showSignal: true,
            showICA: true,
            selectedView: 'Signal',
            IcaMethod: 'FastIca',
            sfreq: null,
            numberChannels: 0,
            channelNames: [],
            icaNames: [],

            toggleCompare: true,
            method_2_method : {'FastIca': 'fastica', 'InfoMax': 'infomax'},

            nComponents: null,

            ica : {},

            checkedICs: [],
            isCheckAll: false,
            ready: true,
            username: this.$store.state.username,
            token: this.$store.state.token,
            role: this.$store.state.role,
            device: this.$store.state.recording.device,
            notes: this.$store.state.recording.notes,
        }
    },
    methods: {
        checkChange(checked) {
            this.checkedICs.push(...checked);
        },
        getData() {
            const options = {
                headers: {'Authorization': 'Bearer ' + this.token}
            };
            const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/' + this.user + '/' + this.rec + '/ICAdata';
            axios.get(path, options)
                .then(res => {
                    this.request = res.data;
                    this.sfreq = this.request.sfreq;
                    this.$refs['sidebar'].nComponents = this.request.num_components;
                    this.$refs['sidebar'].initComponents = Object.keys(this.request.channels).length;
                    this.channelNames =  Object.keys(this.request.channels);
                    this.icaNames =  Object.keys(this.request.ica);
                    this.ica = this.request.ica;

                    this.draw = true;
            })
        },
        setselectedView(option) {
            this.selectedView = option;
        },
        resetLast(name) {
            for (const idx in this.icaNames) {
                    if (name != this.icaNames[idx]) {
                        this.$refs[this.icaNames[idx]][0].lastRect= null;
                    }
                }
        },
        Undo() {
            for (const idx in this.icaNames) {
                this.$refs[this.icaNames[idx]][0].Undo();
                }
        },
        Export() {
            const cleanData = {};
            for (const idx in this.channelNames) {
                    cleanData[this.channelNames[idx]] = this.$refs[this.channelNames[idx]][0].Export();
                }
            const annotations = [];
            for (const idx in this.icaNames) {
                annotations.push(...this.$refs[this.icaNames[idx]][0].Inverse());
                }

            const options = {
                headers: {'Authorization': 'Bearer ' + this.token}
            };
            const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/exportICA/' + this.user + "/" + this.rec + "/" + this.IcaMethod + "/" +this.nComponents;
            axios.post(path, {
                annotations: annotations,
                cleanData: cleanData,
            }, options)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        choiceIca(option) {
            this.IcaMethod = option;
        },
        toggleStims() {
            for (const idx in this.channelNames) {
                this.$refs[this.channelNames[idx]][0].toggleStims();
            };
            for (const idx in this.icaNames) {
                this.$refs[this.icaNames[idx]][0].toggleStims();
            };
        },
        deleteRect(name, selectR) {
            for (const idx in this.icaNames) {
                if (name != this.icaNames[idx]) {
                    if(this.checkedICs.includes(this.icaNames[idx])) {
                        this.$refs[this.icaNames[idx]][0].selection.select(selectR).remove();
                    }
                }
            }
        },
        applyIca(nComponents) {
            this.nComponents = nComponents
            const options = {
                headers: {'Authorization': 'Bearer ' + this.token}
            };
            const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/applyICA/' + this.method_2_method[this.IcaMethod] + '/' + nComponents;
            axios.get(path, options)
                .then(res => {
                    console.log(this.ica);
                    console.log('dkcklads');

                    this.draw = false;

                    setTimeout(() => {
                        console.log(this.ica);
                        this.ica = res.data.ica;
                        this.icaNames =  Object.keys(this.ica);
                        console.log(this.ica);
                        this.draw = true;
                    }, 300)
            })
        },
        inverseIca() {
            const annotations = [];
            for (const idx in this.icaNames) {
                annotations.push(...this.$refs[this.icaNames[idx]][0].Inverse());
                }
            console.log(annotations);

            const options = {
                headers: {'Authorization': 'Bearer ' + this.token},
            };

            const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/inverseICA';
            console.log(path);
            axios.post(path, {
                annotations: annotations
            }, options)
            .then(res => {
                console.log(res.data);
                for (const idx in this.channelNames) {
                    this.$refs[this.channelNames[idx]][0].reDraw(res.data.transformed_data[this.channelNames[idx]]);
                    this.$refs[this.channelNames[idx]][0].cleanedData = res.data.transformed_data[this.channelNames[idx]];
                }
                this.toggleCompare = false;
                this.$refs['sidebar'].toggleCompare();
            })
        },
        Compare() {
            for (const idx in this.channelNames) {
                    this.$refs[this.channelNames[idx]][0].reDraw(this.toggleCompare ? this.$refs[this.channelNames[idx]][0].cleanedData: this.$refs[this.channelNames[idx]][0].timeseries);
                };
            this.toggleCompare = !this.toggleCompare;

        },
        updateCheckall() {
            if(this.checkedICs.length ==  this.icaNames.length){
                this.isCheckAll = true;
            } else{
                this.isCheckAll = false;
            }
        },
        checkAll() {
            this.isCheckAll = !this.isCheckAll;
            this.checkedICs = [];
            if(this.isCheckAll) {
                for (var key in this.icaNames) {
                    this.checkedICs.push(this.icaNames[key]);
                }
            }
        },
        drawRect(name, gSelect, end, start) {
            for (const idx in this.icaNames) {
                if (name != this.icaNames[idx]) {
                    if(this.checkedICs.includes(this.icaNames[idx])) {
                        this.$refs[this.icaNames[idx]][0].drawRectangle(end, start, gSelect);
                    }
                }
            }
        },
    },
    beforeMount() {
        this.getData();
    },
    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == '-') {
                for (const idx in this.channelNames) {
                    this.$refs[this.channelNames[idx]][0].CTrue();
                };
                for (const idx in this.icaNames) {
                    this.$refs[this.icaNames[idx]][0].CTrue();
                }
            }
            if (e.key == '=') {
                for (const idx in this.channelNames) {
                    this.$refs[this.channelNames[idx]][0].XTrue();
                };
                for (const idx in this.icaNames) {
                    this.$refs[this.icaNames[idx]][0].XTrue();
                }
            }
            if (e.key == 'Control') {
                for (const idx in this.icaNames) {
                    this.$refs[this.icaNames[idx]][0].CtrlTrue();
                }
            }
            });
            window.addEventListener('keyup', (e) => {
            if (e.key == 'Control') {
                for (const idx in this.icaNames) {
                    this.$refs[this.icaNames[idx]][0].CtrlFalse();
                }
            }
    });
  },

};
</script>

<style scoped>
#main {
    display: flex;
    flex-direction : column;
    align-items: center;
    justify-content: center;
    font-family: "Trebuchet MS";
}
#ica-plot {
    display: flex;
    flex-direction : row;
    align-items: center;
    justify-content: center;
}
.ica {
    margin-top: 100px;
    display: flex;
    flex-direction : column;
    align-items: center;
    justify-content: center;
}

.signal {
    margin-top: 100px;
    display: flex;
    flex-direction : column;
    align-items: center;
    justify-content: center;
}
.title {
    font-weight: bold;
    font-size: 20px;
    flex: 1;
}

.info {
    height: 70px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.titles-ica {
    padding: 10px;
    margin: 10px;
    background-color: #aca5e7;
    border: 3px solid #2120414a;
    border-radius: 10px;
    cursor: default;
}

.ica-title {
    display: flex;
    flex-direction : row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.checkbox-all-ics{
    width: 10%;
}
.checkbox-all{
    margin-right: 20%;
}
</style>
