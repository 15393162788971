<template>
  <div class="container-items">
    <div
      v-for="(colorCode, label, index) of data"
      :key="index"
      class="item">
      <div class="container-label">
        <div class="label">{{label}}</div>
      </div>
      <div class="dot" :style="{ 'background-color': colorCode }" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegendComponent',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.container-items {
    background-color: #3c3a4b;
    margin: 10%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.item {
    display: flex;
    flex-direction : row;
    align-items: center;
}
.container-label {
    margin-left: 5px;
    width: 80%;
}
.label {
    float: left;
}
.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
}
</style>
