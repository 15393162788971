<template>
  <!-- eslint-disable -->

  <section class="bg-white">
    <div class="lg:grid lg:min-h-screen lg:grid-cols-12">
      <aside class="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
        <img alt="Pattern"
          src="https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          class="absolute inset-0 h-full w-full object-cover" />
      </aside>

      <main class="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
        <div class="max-w-xl lg:max-w-3xl">
          <div class="block text-blue-600">
            <span class="sr-only">Home</span>
            <svg width="140px" height="140px" viewBox="0 0 48 48" id="b" xmlns="http://www.w3.org/2000/svg" fill="#000000"
              stroke="#000000" stroke-width="1.5">
              <g>
                <g id="c">
                  <line id="d" class="o" x1="13.1159" y1="11.7737" x2="13.1159" y2="36.2263" stroke-linecap="round">
                  </line>
                  <line id="e" class="o" x1="8.8039" y1="16.8329" x2="8.8039" y2="31.1671" stroke-linecap="round"></line>
                  <line id="f" class="o" x1="4.5" y1="21.4705" x2="4.5" y2="26.5295" stroke-linecap="round"></line>
                  <line id="g" class="o" x1="17.4569" y1="16.8329" x2="17.4569" y2="31.1671" stroke-linecap="round">
                  </line>
                  <line id="h" class="o" x1="21.9465" y1="21.4705" x2="21.9465" y2="26.5295" stroke-linecap="round">
                  </line>
                </g>
                <g id="i">
                  <line id="j" class="o" x1="34.6693" y1="11.7737" x2="34.6693" y2="36.2263" stroke-linecap="round">
                  </line>
                  <line id="k" class="o" x1="30.3573" y1="16.8329" x2="30.3573" y2="31.1671" stroke-linecap="round">
                  </line>
                  <line id="l" class="o" x1="26.0535" y1="21.4705" x2="26.0535" y2="26.5295" stroke-linecap="round">
                  </line>
                  <line id="m" class="o" x1="39.0104" y1="16.8329" x2="39.0104" y2="31.1671" stroke-linecap="round">
                  </line>
                  <line id="n" class="o" x1="43.5" y1="21.4705" x2="43.5" y2="26.5295" stroke-linecap="round"></line>
                </g>
              </g>
            </svg>
          </div>

          <h1 class="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
            Welcome to AAVAA Labeler ⚡
          </h1>

          <p class="mt-4 leading-relaxed text-gray-500">
            AAVAA's tool for bio-physiological signal annotation, cleaning and more !
          </p>

          <form @submit.prevent class="mt-8 grid grid-cols-6 gap-6">
            <div class="col-span-6">
              <div v-if="showError" role="alert"
                class="text-left w-full rounded border-l-4 border-red-500 bg-red-50 p-1 mb-4">
                <p class=" text-sm text-red-700">
                  <strong class="block font-medium text-red-800"> Something went wrong </strong>
                  Please provide a valid email address and password.
                  If you continue to have issues logging into your account,
                  contact our <span class="text-blue-600 hover:cursor-pointer">Support team.</span>
                </p>
              </div>
              <label :for="Email" class="block text-sm font-medium text-gray-700 text-left">
                Email
                <input v-model="Email" type="email" class="mt-1 w-full rounded-md border-gray-200
              bg-white text-sm text-gray-700 shadow-sm" />
              </label>

              <label :for="Password" class="block text-sm font-medium text-gray-700 pt-4 text-left">
                Password
                <input v-model="Password" type="password" autocomplete="on" class="mt-1 w-full rounded-md border-gray-200
              bg-white text-sm text-gray-700 shadow-sm" />
              </label>
            </div>

            <div class="col-span-6 sm:flex sm:items-center sm:gap-4">
              <button @click="auth" @keydiwn="auth" class="inline-block shrink-0 rounded-md border border-blue-600
              bg-blue-600 px-12 py-3 text-sm font-medium text-gray-50 transition
              hover:bg-transparent hover:text-blue-600 focus:outline-none
              focus:ring active:text-blue-500">
                Log in
              </button>

              <p class=" text-sm text-gray-500 sm:mt-0">
                Don't have an account?
                <span @click="goToHome" @keydown="goToHome"
                  class="text-gray-700 underline hover:cursor-pointer hover:text-blue-500">Sign up
                </span>.
              </p>
            </div>
          </form>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import UserForm from '@/components/UserForm.vue';

axios.defaults.withCredentials = true;

export default {
  name: 'HomeComponent',
  components: {
    UserForm,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      token: null,
      showError: false,

      Email: '',
      Password: '',
    };
  },
  methods: {
    auth() {
      const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/auth';
      axios.post(path, {
        email: this.Email,
        password: this.Password,
      }).then(res => {
        this.saveUserInfo(
          res.data.username,
          res.data.token,
          res.data.role
        );
        this.$router.push({
          name: 'users'
        });
      }).catch(error => {
        if (error.response.status == 401) {
          this.showError = true;
        }
      });
    },
    saveUserInfo(username, token, role) {
      this.$store.commit('setUserInfo', { username, token, role })
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  margin: 40px;
}

p {
  margin: 40px;
}

.form {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

#btn {
  margin: 100px;
}

.form {
  width: 30%;
  margin: auto;
  font-family: "Trebuchet MS";
}

.error {
  height: 50px;
  color: rgb(191, 47, 75);
}
</style>
