<template>
  <div>
    <BaseNavBar
      :username="username"
      :token="token"
      :role="role"
      :user="user"
      :approval="approval"
      :approvalInfo="approvalInfo"
      :annotationsInfo="annotationsInfo"
      @toggle-help="toggleHelp"
      @export="Export"
      @send-approve="sendApprove" />
    <MainLabeler
      ref="mainLabeler"
      @check-peak="checkPeak"
      :user="user"
      :rec="rec"
      :token="token"
      :task="task"
      :device="device"
      :notes="notes"
      :eventsColorMap="eventsColorMap"
      :noiseColorMap="noiseColorMap"
      :selectedEventLabel="selectedEventLabel"
      :selectedNoiseLabel="selectedNoiseLabel"
      :annotationMode="annotationMode"
      :showStims="showStims"
      :showStepSizes="showStepSizes"
      :showEventAnnots="showEventAnnots"
      :showNoiseAnnots="showNoiseAnnots"
      :showTaskOnly="showTaskOnly"
      :showThreshold="showThreshold"
      :showPDDetection="showPDDetection"
      :showPDInterval="showPDInterval" />
    <SideBar
      ref="sideBar"
      :role="role"
      :annotationMode="annotationMode"
      :showStims="showStims"
      :showStepSizes="showStepSizes"
      :showEventAnnots="showEventAnnots"
      :showNoiseAnnots="showNoiseAnnots"
      :showTaskOnly="showTaskOnly"
      :showThreshold="showThreshold"
      :showPDDetection="showPDDetection"
      :showPDInterval="showPDInterval"
      :eventsLabels="eventsLabels"
      :eventsColorMap="eventsColorMap"
      :noiseLabels="noiseLabels"
      :noiseColorMap="noiseColorMap"
      :selectedEventLabel="selectedEventLabel"
      :selectedNoiseLabel="selectedNoiseLabel"
      @selectedLabeling="setselectedLabeling"
      @apply-sampling="applySampling"
      @toggle-step="toggleStep"
      @toggle-stims="toggleStims"
      @toggle-events="toggleEvents"
      @reset-scale="resetScale"
      @clear-charts="clearCharts"
      @choice-event-label="choiceEventLabel"
      @choice-noise-label="choiceNoiseLabel"
      @toggle-annot="toggleAnnot"
      @toggle-model="toggleModel"
      @toggle-thresh="toggleThresh"
      @open-editor="openEditor"
      @toggle-task-only="toggleTaskOnly"
      @apply-pd="applyPD"
      @toggle-pd-interval="togglePDInterval"
      @toggle-pd-detection="togglePDDetection"
      @calculate-snr="calculateSNR" />
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import BaseNavBar from '@/components/labeler/BaseNavBar.vue';
import MainLabeler from '@/components/labeler/MainLabeler.vue';
import SideBar from '@/components/labeler/SideBar.vue';

axios.defaults.withCredentials = true;

export default {
name: "LabelerComponent",
props : ['user', 'rec'],
data() {
  return {
    username: this.$store.state.username,
    token: this.$store.state.token,
    role: this.$store.state.role,
    device: 'None',
    notes: 'None',
    task: 'None',
    approval: false,
    approvalInfo: null,
    annotationsInfo: null,

    annotationMode: "Events",
    showStims: true,
    showStepSizes: false,
    showEventAnnots: true,
    showNoiseAnnots: true,
    showTaskOnly: true,
    showThreshold: false,

    // PD
    showPDDetection: false,
    showPDInterval: false,

    // defaults
    selectedEventLabel: "Blink-Both",
    eventsLabels: ["Blink", "Saccade", "Blink-Left", "Blink-Right", "Blink-Both", "Eyebrows", "WM"],
    eventsColorMap: {
      "Blink-Left": "#A6033F",
      "Blink-Right": "#FF9933",
      "Blink-Both": "#02A676",
      "Blink": "#5050FF",
      "Eyebrows": "#69BBBB",
      "PD": "#6958A6",
      "WM": '#E3CB14',
      "Saccade": "#6958A6",
    },

    selectedNoiseLabel: "EP",
    noiseLabels: ["EP", "NM", "UB", "MV", "FL", "HA", "EA", "ED", "OC", "SP", "AN"],
    noiseColorMap: {
      NM: '#304A7A',
      EP: '#1B7F7A',
      FL: '#FF6B1A',
      HA: '#662400',
      EA: '#151F30',
      ED: '#1BDDF2',
      OC: '#4D0CF8',
      SP: '#BF247A',
      AN: '#49D907',
      MV: '#4D0C38',
      UB: '#151F30',
      B2L: '#151F30',
      B2R: '#151F30',
      R2L: '#151F30',
      R2B: '#151F30',
      L2B: '#151F30',
      L2R: '#151F30',
      'PD-WN': '#02A676',
      'PD-WB': '#6958A6',
    },
  };
},
components: {
    BaseNavBar,
    MainLabeler,
    SideBar,
  },
methods: {
  updateRecordingInfo() {
    const options = {
      headers: { Authorization: "Bearer " + this.token },
    };
    const path =
      location.protocol +
      "//" +
      location.hostname +
      process.env.VUE_APP_API_ENDPOINT +
      "/recordings/" +
      this.user +
      "/" +
      this.rec +
      "/metadata";
    axios.get(path, options).then((res) => {
      if (res.data.device != null) {
        this.device = res.data.device.name;
      }
      if (res.data.task != null) {
        this.notes = res.data.task.user_note;
        this.task = res.data.task.experiment_id;
      }
      if (res.data.annotations != null) {
        this.annotationsInfo = res.data.annotations.event;
        this.approvalInfo = res.data.annotations.approval;
        this.approval = (this.approvalInfo != null);
      }
    });
  },
  applySampling(minFreq, maxFreq, median, diff, accel, accelxvel) {
    this.$refs.mainLabeler.applySampling(
      minFreq, maxFreq, median, diff, accel, accelxvel);
  },
  clearCharts() {
    this.$refs.mainLabeler.clearAnnots();
    this.calculateSNR();
  },
  toggleStep() {
    this.showStepSizes = !this.showStepSizes;
  },
  toggleStims() {
    this.showStims = !this.showStims;
  },
  toggleAnnot() {
    this.showNoiseAnnots = !this.showNoiseAnnots;
  },
  toggleThresh() {
    this.showThreshold = !this.showThreshold;
  },
  toggleEvents() {
    this.showEventAnnots = !this.showEventAnnots;
  },
  resetScale() {
    this.$refs.mainLabeler.resetScale();
  },
  choiceEventLabel(selected) {
    this.selectedEventLabel = selected;
    this.annotationMode = "Events";
  },
  choiceNoiseLabel(selected) {
    this.selectedNoiseLabel = selected;
    this.annotationMode = "Noise";
  },
  checkPeak() {
    this.$refs.sideBar.checkPeak=true;
  },
  Export() {
    const data = this.$refs.mainLabeler.Export();
    const options = {
      headers: { Authorization: "Bearer " + this.token },
    };
    const path =
      location.protocol +
      "//" +
      location.hostname +
      process.env.VUE_APP_API_ENDPOINT +
      "/export/" +
      this.user +
      "/" +
      this.rec;

    // this.showModalExport = true;

    axios.post(
      path,
      data,
      options,
    )
    .then((res) => {
      this.updateRecordingInfo();
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  setselectedLabeling(option) {
    this.annotationMode = option;
  },
  toggleModel() {
    this.$refs.mainLabeler.toggleModel();
  },
  openEditor() {
    this.$refs.mainLabeler.openEditor();
  },
  toggleHelp() {
    this.$refs.mainLabeler.toggleHelp();
  },
  sendApprove() {
    const state = !this.approval;
    // sends a request to the backend to send the approval option and update the recording annotations
    const options = {
      headers: { Authorization: "Bearer " + this.token },
    };
    const path =
      location.protocol +
      "//" +
      location.hostname +
      process.env.VUE_APP_API_ENDPOINT +
      "/approve/" +
      this.user +
      "/" +
      this.rec +
      "/" +
      state;
    axios.post(path, {}, options)
    .then((res) => {
      this.updateRecordingInfo();
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  Undo() {
    this.$refs.mainLabeler.Undo();
  },
  toggleTaskOnly() {
    this.showTaskOnly = !this.showTaskOnly;
  },
  applyPD(sensitivity) {
    this.$refs.mainLabeler.applyPD(sensitivity);
    this.showPDDetection = true;
    this.showPDInterval = true;
  },
  togglePDInterval() {
    this.showPDInterval = !this.showPDInterval;
  },
  togglePDDetection() {
    this.showPDDetection = !this.showPDDetection;
  },
  calculateSNR() {
    this.$refs.mainLabeler.calculateSNR();
  },
  keydownListener(event) {
    if (event.key == '-') {
      this.$refs.mainLabeler.CTrue();
    }
    if (event.key == '=') {
      this.$refs.mainLabeler.XTrue();
    }
    if (event.ctrlKey || event.metaKey) {
      this.$refs.mainLabeler.CtrlTrue();
    }
    this.handleShortcuts(event);
  },
  keyupListener(event) {
    if ((event.key == 'Control') || (event.key == 'Meta')) {
      this.$refs.mainLabeler.CtrlFalse();
    }
  },
  handleShortcuts(event) {
    if (this.role === 'viewer') {
      return;
    }
    // Check if the Control key is pressed and the key is 'z'
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      this.Undo();
      event.preventDefault();
    }
    if (event.key === 'q') {
      this.choiceEventLabel("Blink-Left");
      this.annotationMode = "Events";
      event.preventDefault();
    }
    if (event.key === 'w') {
      this.choiceEventLabel("Blink-Both");
      this.annotationMode = "Events";
      event.preventDefault();
    }
    if (event.key === 'e') {
      this.choiceEventLabel("Blink-Right");
      event.preventDefault();
    }
    if (event.key === 'a') {
      this.choiceEventLabel("WM");
      event.preventDefault();
    }
    if (event.key === 's') {
      this.choiceNoiseLabel("NM");
      event.preventDefault();
    }
    if (event.key === 'd') {
      this.choiceNoiseLabel("EP");
      event.preventDefault();
    }
    if (event.key === ',') {
      this.annotationMode = "Events";
      event.preventDefault();
    }
    if (event.key === '.') {
      this.annotationMode = "Noise";
      event.preventDefault();
    }
  },
},
created() {
    window.addEventListener('keydown', this.keydownListener);
    window.addEventListener('keyup', this.keyupListener);
  },
mounted() {
  this.updateRecordingInfo();
},
beforeUnmount() {
  window.removeEventListener('keydown', this.keydownListener);
  window.removeEventListener('keyup', this.keyupListener);
},
};
</script>
