<template>
  <nav class="navbar navbar-expand-lg fixed-top">
    <div class="navbar-nav">
      <!-- <div id="sidebar-btn">
        <span
          class="collapse-icon"
          :class="{ 'rotate-180': !collapsed }"
          @click="toggleSidebar"
          @keydown="toggleSidebar"
        >
          <i class="fa-solid fa-bars fa-xl" />
        </span>
      </div> -->

      <div class="aavaa-labeler">
        <router-link
          class="nav-link"
          v-bind:to="'/'"
        >Exit</router-link>
      </div>
      <div>
        <router-link
          class="nav-link"
          v-bind:to="'/users'">Users</router-link>
      </div>
      <div>
        <router-link
          class="nav-link"
          v-bind:to="'/recordings/' + user"
        >Recordings</router-link>
      </div>
      <div>
        <div
          class="nav-link"
          @click="$emit('toggle-help')"
          @keydown="$emit('toggle-help')"
          v-show="!(this.role === 'viewer')"
        >Help</div>
      </div>
    </div>

    <!-- Submit and Approve buttons box in the middle -->
    <div class="d-flex mx-auto">
      <!-- Export -->
      <div class="btn-container" v-show="!(this.role === 'viewer')">
        <div
          class="comments-btn"
          @click="submitted"
          @keydown="submitted">
          <div class="editor-icon" v-if="submitWait">
            <i class="fa-solid fa-check-to-slot" />
          </div>
          <div class="editor-icon" v-else>
            <i class="fa-solid fa-cloud-arrow-up" />
          </div>
          <div v-if="submitWait">
            <span>Submitted</span>
          </div>
          <div v-else>
            <span>Submit</span>
          </div>
        </div>
      </div>

      <!-- Approve -->
      <div class="btn-container" v-show="((this.role === 'admin') || (this.role === 'owner')) && annotationsInfo">
        <div class="comments-btn" @click="approved" @keydown="approved">
          <div class="editor-icon" v-if="approvalWait">
            <i class="fa-solid fa-spinner fa-spin-pulse" />
          </div>
          <div class="editor-icon" v-else-if="approval">
            <i class="fa-solid fa-circle-check" />
          </div>
          <div class="editor-icon" v-else>
            <i class="fa-solid fa-circle-question" />
          </div>
          <div v-if="approvalWait & !approval">
            <span>Approving...</span>
          </div>
          <div v-else-if="approvalWait & approval">
            <span>Disapproving...</span>
          </div>
          <div v-else-if="approval">
            <span>Approved</span>
          </div>
          <div v-else>
            <span>Approve</span>
          </div>
        </div>
      </div>
    </div>

    <div class="info-box" v-if="(annotationsInfo || approvalInfo)">
      <div class="info-text" v-if="annotationsInfo">
        Annotated by <b> {{ capitalize(annotationsInfo.annotator.first_name) }} </b>
        at {{ convertTimeToLocal(annotationsInfo.date, annotationsInfo.time) }}
      </div>
      <div class="info-text" v-if="approvalInfo">
        Approved by <b> {{ capitalize(approvalInfo.annotator.first_name) }} </b>
        at {{ convertTimeToLocal(approvalInfo.date, approvalInfo.time) }}
      </div>
    </div>

    <!-- Annotator and Role box on the right -->
    <div class="d-flex ml-auto">
      <div class="user-container">
        <span class="annotator"> Logged in as |&ensp;<span class="user"> {{ username }} </span></span>
      </div>
      <div class="user-container">
        <span class="annotator"> Role |&ensp;<span class="user">{{ capitalize(role) }} </span></span>
      </div>
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
import { collapsed, toggleSidebar} from '@/components/sidebar/state';

export default {
  name: "BaseNavBar",
  props : ['token', 'username', 'role', 'user', 'approval',
            'approvalInfo', 'annotationsInfo'],
  emits: ['toggle-help', 'export', 'send-approve'],
  data() {
    return {
      approvalWait: false,
      submitWait: false,
    };
  },
  setup() {
    return { collapsed, toggleSidebar }
  },
  watch: {
    approval() {
      this.approvalWait = false;
    },
  },
  methods: {
    convertTimeToLocal(date, time) {
      const utcDate = date + 'T' + time + 'Z';
      const nowDate = new Date(utcDate);
      return nowDate.toLocaleString();
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    approved() {
      this.approvalWait = true;
      this.$emit("send-approve");
    },
    submitted() {
      this.submitWait = true;
      this.$emit("export");
      setTimeout(() => {
        this.submitWait = false;
      }, 1000);
    },
  },
  mounted() {
  }
};

</script>

<style scoped>

.aavaa-labeler {
  margin-left: 20px;
}
.navbar {
  height: 6vh;
  background:#202529;
}
.nav-link , .navbar-brand {
  color: #f4f4f4;
  cursor: pointer;
}
.nav-link {
  margin-right: 1em !important;
  font-family: "Trebuchet MS";
}
.nav-link:hover { background: #ffffff22; color: #ffffff; }
.navbar-nav { float: right; }

#sidebar-btn{
    margin-right: 55px;
    margin-left: 12px;
}
.info-box {
  height: 5vh;
  font-size: min(2vh, .7vw);
  border: 2px solid #aaa;
  background-color: whitesmoke;
  border-radius: 4px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  float: left;
  flex-direction: column; /* Set the flex direction to column */
}

.info-text {
    color: black;
    float: left;
}

.user-container{
    padding-left: 10px;
    padding-right: 10px;
}

.btn-container{
    min-width:150px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: flex-end;
    align-items: right;
}

.user{
    color: #ffffff;
    float: right;
    font-weight: bold;
}
.annotator {
    color: #ffffff;
    float: right;
}

.comments-btn {
  height: 3.5vh;
  width: 9vw;
  display: flex;
  flex-direction: row;
  align-items: center; /* Vertical alignment */
  justify-content: left; /* Horizontal alignment */
  padding-left: 1vw;
  cursor: pointer;
  background-color: whitesmoke;
  border-radius: 10px;
}
.comments-btn:hover {
  display: flex;
  flex-direction: row;
  align-items: right;
  cursor: pointer;
  background-color: lightblue;
  border-radius: 10px;
}

.editor-icon {
  width: 30%;
}
</style>