<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask-cmnt">
      <div class="modal-wrapper-cmnt">
        <div class="modal-container-cmnt">
          <div class="modal-header-cmnt">
            <slot name="header">default header</slot>
          </div>

          <div class="modal-body-cmnt">
            <slot name="body">default body</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask-cmnt {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper-cmnt {
  display: table-cell;
  vertical-align: middle;
}

.modal-container-cmnt {
  width: 1100px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #32383D;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header-cmnt{
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
