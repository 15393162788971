import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    username: '',
    token: '',
    role: '',
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, {
      username, token, role,
    }) {
      state.username = username;
      state.token = token;
      state.role = role;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(),
  ],
});
