<script>
/* eslint-disable */
export default {
  props: {
    data: Array,
    keys: Array,
    columns: Array,
    filterKey: String,
    sortKey: {
      type: String,
      default: "", // Default value can be overridden by parent component
    },
    sortOrder: {
      type: Number,
      default: 1, // Default sort order, can be overridden by parent component
      validator(value) {
        // Ensure sortOrder is either 1 or -1
        return value === 1 || value === -1;
      }
    },
  },
  data() {
    return {
      currentSortKey: this.sortKey,
      sortOrders: this.keys.reduce((o, key) => {
        o[key] = key === this.sortKey ? this.sortOrder : 1;
        return o;
      }, {}),
    };
  },
  emits: ["clicked"],
  computed: {
    filteredData() {
      const sortKey = this.currentSortKey;
      const filterKey = this.filterKey && this.filterKey.toLowerCase();
      const order = this.sortOrders[sortKey] || 1;
      let data = this.data;
      if (filterKey) {
        data = data.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (sortKey) {
        data = data.slice().sort((a, b) => {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
  },
  methods: {
    sortBy(key) {
      this.currentSortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<template>
  <v-table v-if="filteredData.length">
    <thead>
      <tr>
        <th v-for="(key, index) in columns" :key="key.name" @click="sortBy(keys[index])"
          :class="{ active: currentSortKey == keys[index] }">
          {{ capitalize(key) }}
          <span class="arrow" :class="sortOrders[keys[index]] > 0 ? 'dsc' : 'asc'">
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="entry in filteredData" v-bind:key="entry" @click="$emit('clicked', entry)">
        <td v-for="key in keys" v-bind:key="key">
          <div class="table-row">
            {{ entry[key] }}
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
  <div v-else>
    <h6 class="nomatch">no matches found.</h6>
  </div>
</template>

<style>
.nomatch {
  color: #6c8b97; /* Lighter shade of #0c526a */
  text-align: center;
  font-size: 1.2em;
  margin: 20px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

th {
  background-color: #0c526a; /* Base color */
  color: #ffffff;
  padding: 15px 20px;
  text-align: left;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease;
  position: relative;
}

th.active {
  background-color: #084d60; /* Darker shade of #0c526a */
}

td {
  background-color: #ffffff; /* Very light shade of #0c526a */
  padding: 15px 20px;
  font-size: 0.9em;
  color: #333;
  border-bottom: 1px solid #b2d0d4; /* Light shade of #0c526a */
}

th:hover {
  background-color: #056e7c; /* Slightly lighter shade of #0c526a */
}

th.active:hover {
  background-color: #044d55; /* Darker shade of #0c526a */
}

th,
td {
  min-width: 100px;
}

tbody tr {
  transition: background-color 0.3s ease, border-color 0.3s ease;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

tbody tr:hover {
  background-color: #b2d0d4; /* Light shade of #0c526a */
  border-left-color: #056e7c; /* Slightly lighter shade of #0c526a */
  border-right-color: #056e7c;
  cursor: pointer;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.arrow.asc {
  transform: rotate(-135deg);
}

.arrow.dsc {
  transform: rotate(45deg);
}

.table-row {
  cursor: pointer;
}
</style>