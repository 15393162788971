<template>
  <div class="main-container" :class="{ 'left-check': checkbox, 'right-check': !checkbox }">
    <div class="left-container">
      <div class="left">{{ optionLeft }}</div>
    </div>
    <div class="switch-container">
      <label class="switch" for="checkbox-input">
        <input id="checkbox-input" type="checkbox" :checked="!checkbox" @click="toggleCheckbox">
        <div class="slider round" />
      </label>
    </div>
    <div class="right-container">
      <div class="right">{{ optionRight }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchComponent',
  props: {
    optionLeft: {
      type: String,
      required: true,
    },
    optionRight: {
      type: String,
      required: true,
    },
    selected: {
      type: String,
      default: 'Events',
      required: false,
    },
  },
  emits: ['selectedLabeling'],
  watch: {
    selected(newVal) {
      this.checkbox = (newVal === this.optionLeft);
    },
    checkbox(newVal) {
      this.$emit('selectedLabeling', newVal ? this.optionLeft : this.optionRight);
    },
  },
  data() {
    return {
      checkbox: (this.selected === this.optionLeft),
    };
  },
  methods: {
    toggleCheckbox() {
      this.checkbox = !this.checkbox;
    },
  },
};
</script>

<style scoped lang="scss">
.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.right-check {
  background: linear-gradient(90deg, transparent 50%, rgba(216, 101, 101, 0.778) 50%);
}

.left-check {
  background: linear-gradient(90deg, #5e86e2a5 50%, transparent 50%);
}

.switch-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right {
  margin-right: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.left {
  margin-left: 10px;
  padding-right: 2px;
  padding-left: 2px;
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #7f9dde;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: lightcoral;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
