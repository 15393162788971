<template>
  <div class="main">
    <div>
      <div class="back-users">
        <div class="back-back-users">
          <span
            class="back-back-back-users"
            @click="goToUsers"
            @keydown="goToUsers"
          >
            <i class="fa-solid fa-chevron-left" />
            <span class="span-users">users</span>
          </span>
        </div>
      </div>
    </div>
    <div class="search-container flex flex-row gap-4">
      <div class="search">
        <v-text-field v-model="match" label="search" />
      </div>

      <div class="flex place-items-center">
        <div
          v-if="!aborted"
          @click="
            aborted = !aborted;
            getRecordingIds();
          "
          @keydown="
            aborted = !aborted;
            getRecordingIds();
          "
          class="p-2 bg-blue-300 rounded-lg cursor-pointer"
        >
          keep aborted
        </div>
        <div
          v-else
          @click="
            aborted = !aborted;
            getRecordingIds();
          "
          @keydown="
            aborted = !aborted;
            getRecordingIds();
          "
          class="p-2 bg-violet-300 rounded-lg cursor-pointer"
        >
          remove aborted
        </div>
      </div>
    </div>
    <div class="table-recordings">
      <TableGrid
        :data="recordings"
        :keys="keys"
        :sortKey="'recording_id'"
        :sortOrder=-1
        :columns="header"
        :filter-key="match"
        @clicked="(row) => rowClicked(row)"
      />
    </div>
    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <ChoiceModal :show="showModal" @close="showModal = false">
        <template #header>
          <div class="header-ChoiceModal">
            <h3>Please choose an option</h3>
          </div>
          <div
            class="icon-ChoiceModal"
            @click="showModal = false"
            @keydown="showModal = false"
          >
            <i class="fa-solid fa-xmark fa-xl" />
          </div>
        </template>
        <template #body>
          <div class="container-ChoiceModal">
            <div
              class="ica-choice-btn"
              @click="goToLabeler"
              @keydown="goToLabeler"
            >
              <div class="modal-editor-icon">
                <div class="modal-icon">
                  <i class="fa-solid fa-share" />
                </div>
              </div>
              <div>
                <span>Annotation</span>
              </div>
            </div>
            <!-- <div class="ica-choice-btn" @click="goToICA" @keydown="goToICA">
              <div class="modal-editor-icon">
                <div class="modal-icon">
                  <i class="fa-solid fa-share" />
                </div>
              </div>
              <div>
                <span>Artifact Remover</span>
              </div>
            </div> -->
          </div>
        </template>
      </ChoiceModal>
    </Teleport>
  </div>
</template>

<script>
/* eslint-disable */
import TableGrid from "./TableGrid.vue";
import ChoiceModal from "./ChoiceModal.vue";
import axios from "axios";

export default {
  props: ["user"],
  data() {
    return {
      date_from: "2020-01-01",
      date_to: null,
      clean_index: 0.0,

      match: "",

      aborted: false,

      header: [
        "Recording ID",
        "Task",
        "Device",
        "MAC",
        "#channels",
        "Annotation Date",
        "Annotator",
        "SNR Average",
        "Approval",
        "Notes",
      ],
      recordings: [],
      keys: [
        "recording_id",
        "task",
        "device_name",
        "device_mac",
        "n_channels",
        "event_annotations_date",
        "event_annotator",
        "snr_average",
        "approval",
        "notes",
      ],
      showModal: false,
      username: this.$store.state.username,
      token: this.$store.state.token,
      role: this.$store.state.role,
    };
  },
  components: {
    TableGrid,
    ChoiceModal,
  },
  methods: {
    goToLabeler() {
      this.$router.push({
        name: "labeler",
        params: {
          user: this.user,
          rec: this.rec,
        },
      })
      .catch((error) => {
        console.error(error);
        this.$router.push({
          name: "home",
        });
      });
    },
    goToICA() {
      this.$router.push({
        name: "ica",
        params: {
          user: this.user,
          rec: this.rec,
        },
      })
      .catch((error) => {
        console.error(error);
        this.$router.push({
          name: "home",
        });
      });
    },
    goToUsers() {
      this.$router.push({
        name: "users",
      });
    },
    getRecordingIds() {
      const options = {
        headers: { Authorization: "Bearer " + this.token },
      };
      const path =
        location.protocol +
        "//" +
        location.hostname +
        process.env.VUE_APP_API_ENDPOINT +
        "/recordings" +
        "/" +
        this.user +
        "/" +
        this.aborted;
      axios
        .get(path, options)
        .then((res) => {
          this.request = res.data;
          this.recordings = this.request[this.user];
        })
        .catch((error) => {
          console.error(error);
          this.$router.push({
            name: "home",
          });
        });
    },
    rowClicked(row) {
      this.rec = row.recording_id;
      this.goToLabeler();
    },
  },
  created() {
    this.getRecordingIds();
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.search-container {
  display: flex;
  justify-content: center;
}
.search {
  width: 50%;
}

.table-recordings {
  padding-left: 4rem;
  padding-right: 4rem;
}

.icon-ChoiceModal {
  color: #ffffff;
  width: 5%;
  cursor: pointer;
}
.header-ChoiceModal {
  flex: 1;
  color: #ffffff;
}
.container-ChoiceModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ica-choice-btn {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: #7f84de1a;
  border-radius: 10px;
  width: 50%;
  margin-top: 20px;
}
.ica-choice-btn:hover {
  background-color: #7f8dde4a;
}
.ica-choice-btn:active {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: 1px solid rgb(255, 255, 255);
}
.back-back-users {
  margin-right: 60%;
  align-items: center;
  justify-content: center;
}
.back-back-back-users:hover {
  cursor: pointer;
}
.span-users {
  margin-left: 10px;
  text-decoration: underline;
}
.back-users {
  margin-right: 80%;
  height: 30px;
}
</style>
