<template>
  <div class="menu-item">
    <div class="header" @click="toggleMenu" @keydown="toggleMenu">
      <div class="title-container">
        <div class="title">
          {{title}}
        </div>
      </div>
      <div class="icon" :class="{ 'rotate-180': !showSection }">
        <i class="fa-solid fa-angle-up" />
      </div>
    </div>

    <div class="children-wrapper" v-show="showSection">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuComponent',
  props: {
    title: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showSection: this.open,
    };
  },
  methods: {
    toggleMenu() {
      this.showSection = !this.showSection;
    },
  },
};
</script>

<style scoped lang="scss">
.menu-item {
  font-family: "Trebuchet MS";
}
.header {
  display: flex;
  flex-direction : row;
  align-items: center;
  cursor: pointer;
  height: 3em;
  padding: 1px;
  border-radius: 4px;
}
.header:hover {
   background-color: #8f7fde15;
}
.header:active {
   background-color: #8f7fde34;
}

.title-container{
  width: 90%;
}
.title{
  float: left;
  margin-left: 5%;
  padding: 5px;
  font-weight: bold;
  font-size: 18px;
}
.rotate-180 {
    transform: rotate(180deg);
    transition: 0.2s linear;
}
.icon {
  transition: 0.2s linear;
}

.children-wrapper {
  margin-top: 10px;
  margin-left: 5%;
  margin-right: 5%;
}
</style>
