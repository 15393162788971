<template>
  <div class="container-plot">
    <div class="scale">
      <div>{{ 2 * Math.round(YScale) }} μV</div>
    </div>
    <div
      :id="'container-svg-' + channelName"
      :style="{ height: this.height }"
      class="container-svg"
    >
      <svg :id="'svg-' + channelName" class="div-channel-plot">
        <g class="step" />
        <g class="step-axis" />
        <g class="time-axis" />
        <g class="stims" />
        <g class="origin" />
        <g class="signal-line" />
        <g class="thresholds" />
        <g class="pd-intervals" />
        <g class="pd-detections" />
        <g class="events-pointers" />
        <g class="rectangles-noise" />
        <g class="rectangles-events" />
      </svg>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
/* eslint prefer-template: "off" */

import * as d3 from "d3";

export default {
  name: "ContextContent",
  components: {},
  emits: [
    "draw-rect",
    "change-yscale",
    "delete-rect",
  ],
  props: {
    eventsColorMap: Object,
    noiseColorMap: Object,
    selectedEventLabel: String,
    selectedNoiseLabel: String,
    annotationMode: String,
    timeseries: Array,
    channelName: String,
    checkedChannels: Array,
    annot: Object,
    stims: Object,
    sfreq: Number,
    indexTask: Number,
    showStims: Boolean,
    showStepSizes: Boolean,
    showEventAnnots: Boolean,
    showNoiseAnnots: Boolean,
    showTaskOnly: Boolean,
    showThreshold: Boolean,
    showPDDetection: Boolean,
    showPDInterval: Boolean,
  },
  watch: {
    showStims(newValue) {
      this.updateVisibility("g.stims", newValue);
    },
    showStepSizes(newValue) {
      this.updateVisibility("g.step-axis", newValue);
      this.updateVisibility("g.step", newValue);
    },
    showEventAnnots(newValue) {
      this.updateVisibility("g.rectangles-events", newValue);
    },
    showNoiseAnnots(newValue) {
      this.updateVisibility("g.rectangles-noise", newValue);
    },
    showThreshold(newValue) {
      this.updateVisibility("g.thresholds", newValue);
    },
    showTaskOnly() {
      this.reDraw()
    },
    showPDDetection(newValue) {
      this.updateVisibility("g.pd-detections", newValue);
    },
    showPDInterval(newValue) {
      this.updateVisibility("g.pd-intervals", newValue);
    },
  },
  data() {
    return {
      width: parseInt((window.innerWidth - 220) * 0.82),
      height: parseInt((window.innerHeight - 180) * 0.2),

      data: this.timeseries,
      annotations: this.annot,
      selection: null,
      path: null,
      mainLine: null,
      brush: null,
      zoomDrag: null,
      hoverBox: null,
      showAnnotNoiseAuto: true,
      showThresh: true,
      showModel: true,
      showPeaks: true,
      showPDDetections: true,

      initYScale: 25,
      YScale: 25,
      threshold: 15,
      upperThresh: null,
      downThresh: null,
      peakData: null,
      peakDetections: null,
      origin: null,
      zoomStep: 0.1,

      xScale: null,
      yScale: null,

      undoActionStack: [],

      taskData: null,
    };
  },
  methods: {
    draw(data, resize=false) {
      // This fct draws the line for the visualization, sets up the brush for annotation and other ops for visualization

      // d3.scaleLinear(): creates a scale with a linear relationship between input and output
      //                   the domain is between 0 and the eeg data length, and the range is the width of the window in terms of pixels
      if (data == null) {
        data = this.data;
      } else {
        this.data = data;
      }

      const taskData = data.slice(this.indexTask);
      data = this.showTaskOnly ? taskData : data;

      this.xScale = d3
        .scaleLinear()
        .domain([0, data.length])
        .range([0, this.width]);
      this.yScale = d3
        .scaleLinear()
        .domain([-this.YScale, this.YScale])
        .range([this.height, 0]);

      // d3.line() : creates a shape that follows a sequence of points. Its default curve interpolator connects
      //             the points with straight line segments, creating a polygonal chain or polyline.
      //             the x method sets the x coordinates values, the y method sets the y coordinates values
      this.mainLine = d3
        .line()
        .curve(d3.curveLinear)
        .x((d, i) => this.xScale(i))
        .y((d) => this.yScale(d));

      // we store the #svg html tag unto a javascript variable, so that we can access different components and attributes of the svg
      this.selection = d3.select("#svg-" + this.channelName);

      // we set the height and width of the #svg
      this.selection
        .attr("width", this.width)
        .attr("height", this.height + 5)
        .attr("viewBox", "0 0 " + this.width + " " + this.height);

      // we draw the line using the eeg data
      this.path = this.drawLine(data);

      // d3.brushX() : Brushing is the interactive specification a one- or two-dimensional selected region using a pointing gesture, such as by clicking and dragging the mouse.
      //        the extent is where for specifying the coordinates of the limits of the brushing
      //        `on` specifies an event listener
      //        filter method
      this.brush = d3
        .brushX()
        .filter(this.filterBrush)
        .extent([
          [0, 0],
          [this.width, this.height],
        ])
        .on("brush", this.onBrush)
        .on("end", this.handleBrush);

      // d3.zoom(): D3 can add zoom and pan behaviour to an HTML or SVG element.
      //   used for dragging the plot singal using the mouse, on top or down
      this.zoomDrag = d3.zoom().on("zoom", this.zoomedDrag);

      // g.event-pointers : is an SVG layer that will contain all the event pointers : brush, zoom
      // we call these event pointers on this layer
      this.selection
        .select("g.events-pointers")
        .call(this.brush)
        .call(this.zoomDrag)
        .on("wheel.zoom", null)
        .call(this.enableDefaultCursor);

      // helper function to draw annotations
      this.drawAnnot();
      // helper function to draw the the step size
      this.drawStep();
      // helper function to draw the stimilus
      this.drawStims();
      // helper function to draw the time scale axis
      this.drawtimeScale();
      // helper function to draw the red noise thresholds
      this.drawThreshold();
      // PD
      this.drawPDIntervals();
      this.drawPDDetections();
      // helper function to draw the origin line, that represents the center of x axis
      if (!resize) {
        this.drawOrigin();
      }
    },
    drawAnnot() {
      // plots the json annotations that come from the DB, it selects the appropriate `g` element the add the rectangles on,
      // add the event listeners to delete when shift-click and adds the text description on the bottom of the rectangle
      const task_index = this.showTaskOnly ? this.indexTask : 0;
      for (const annotType in this.annotations) {
        this.annotations[annotType].forEach((annotInfo) => {
          let selectG;
          let label;

          var start = this.xScale(annotInfo["onset"] - task_index);
          var end = this.xScale(annotInfo["offset"] - task_index);

          if (annotType == "noise") {
            label = annotInfo["description"];
            selectG = "g.rectangles-noise";
          } else if (annotType == "events") {
            label = annotInfo["description"];
            selectG = "g.rectangles-events";
          }
          this.drawRectangle(end, start, selectG, label, false);
        });
      }
    },
    drawtimeScale() {
      // g.time-axis : graphical element that contains the time-axis
      //        we apply the call function to add a d3.axisBottom, that is used to create a bottom horizontal axis
      //        we specify the domain, which is the length of the timeseries data (whether with cropped task index or not)
      //        and we specify a range, which is between 0 and the width of the window in terms of nmber of pixels
      //        - the .tickSize specifies the size, and .tickFormat is used to implement your own tick format function.
      const task_index = this.showTaskOnly ? this.indexTask : 0;
      this.selection
        .select("g.time-axis")
        .style("font-size", "9px")
        .call(
          d3
            .axisBottom(
              d3
                .scaleLinear()
                .domain([0, (this.data.length - task_index) / this.sfreq])
                .range([0, this.width])
            )
            .tickSize(3)
            .tickFormat((d) => d + "s")
        );
    },
    zoomedDrag(event) {
      // this fct will redraw the path line of the timeseries data with the corresponding y coordinates of each point, the new coordinates
      // correspond to the transformed y coordinates with applied zoom drag transform.
      // It will also redraw the noise threshold with the same transformation
      this.path.attr(
        "d",
        d3
          .line()
          .curve(d3.curveLinear)
          .x((d, i) => this.xScale(i))
          .y((d) => this.yScale(d - event.transform.y))
      );
      this.upperThresh.attr(
        "y",
        this.yScale(this.threshold - event.transform.y)
      );
      this.downThresh.attr(
        "y",
        this.yScale(-this.threshold - event.transform.y)
      );
      this.downThresh.attr(
        "y",
        this.yScale(-this.threshold - event.transform.y)
      );

      this.origin.attr("y", this.yScale(-event.transform.y));
    },
    filterBrush(event) {
      // filters the brush event handler, if the control key is pressed, it will return true, therefore the brush operation will be performed
      // otherwise, it will return false.
      return event.ctrlKey || event.metaKey;
    },
    enableDefaultCursor(g) {
      // will apply default cursor ui on the specified g element
      return g.select(".overlay").style("cursor", "default");
    },

    onBrush() {
      // event handler, while the brush event is done, it will set the extent class of all the rectangles to false
      this.selection.selectAll("rect").classed("extent", false);
    },
    handleBrush(event) {
      // event handler for when the brush event has ended. It will get the coordinates of the brushing area and will add a rectangle
      // corresponding to the annotation, with the specified color
      // the rectangle will be added in either g.rectangles-events if it is an event annotatoin or g.rectangles-noise if
      // it is a noise annotation.
      // The function will also emit an event to the vuejs parent component called `draw-rect` that will draw the same rectangle on other
      // channel view component if their corresponding checkbox is active.
      if (event.selection != null) {
        if (Math.abs(event.selection[0] - event.selection[1]) >= 1) {
          let start;
          let end;
          if (event.selection[0] < event.selection[1]) {
            start = Math.round(event.selection[0]);
            end = Math.round(event.selection[1]);
          } else {
            start = Math.round(event.selection[1]);
            end = Math.round(event.selection[0]);
          }

          // Rectangle
          let selectG;
          let label;
          if (this.annotationMode == "Events") {
            selectG = "g.rectangles-events";
            label = this.selectedEventLabel;
          } else {
            selectG = "g.rectangles-noise";
            label = this.selectedNoiseLabel;
          }

          if (this.checkedChannels.length > 0) {
            this.$emit("draw-rect", this.channelName, end, start, selectG, label);
          }

          this.selection.select("g.events-pointers").call(this.brush.move, null);
        }
      }
    },
    setHoverBox(selection) {
      return selection
        .append("div")
        .attr("class", "hoverbox")
        .style("opacity", 0)
        .style("position", "absolute")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "2px");
    },
    drawRectangle(end, start, selectG, label, original=true) {
      // this function handles drawing the rectangles, and more importantly adds annotation data inside each rectangle
      // in the .datum field. It bind the rectangle with the event handler that enable deletion by shift-click.
      // It also adds a text decription of the annotation in bottom of the rectangle.
      var self = this;

      let color;
      let type;

      const task_index = this.showTaskOnly ? this.indexTask : 0;

      if (selectG.includes("events")) {
        color = this.eventsColorMap[label];
        type = "events";
      } else if (selectG.includes("noise")) {
        color = this.noiseColorMap[label];
        type = "noise";
      }

      const onset = Math.round(this.xScale.invert(start)) + task_index;
      const offset = Math.round(this.xScale.invert(end)) + task_index;
      var rect = null;
      var text = null;

      rect = self.selection
        .select(selectG)
        .append("rect")
        .style("fill", color)
        .attr("stroke", "black")
        .attr("opacity", "0.2")
        .attr("stroke-opacity", 0)
        .attr("x", "" + start)
        .attr("y", "0")
        .attr("width", "" + end - start)
        .attr("height", "" + this.height)
        .attr("id", "r-" + onset + "-" + offset + "-" + label)
        .datum({
          channel: this.channelName,
          onset: onset,
          offset: offset,
          description: label,
          type: type,
        })
        .on("click", function (event) {
          if (event.shiftKey) {
            self.removeRectangle(end, start, selectG, label);
          }
        });
      text = self.selection
        .select(selectG)
        .append("text")
        .attr("id", "t-" + onset + "-" + label)
        .attr("x", start)
        .attr("y", this.height)
        .attr("dx", "2px")
        .attr("dy", "-2px")
        .datum({
          channel: this.channelName,
          onset: onset,
          offset: offset,
        })
        .style("font-size", "10px")
        .text(label);

      if (original) {
        this.undoActionStack.push(
          () => {
            rect.remove();
            text.remove();
          }
        );
      }
    },
    removeRectangle(end, start, selectG, label) {
      var self = this;
      const task_index = this.showTaskOnly ? this.indexTask : 0;
      const onset = Math.round(this.xScale.invert(start)) + task_index;
      const offset = Math.round(this.xScale.invert(end)) + task_index;

      const selectT = "#t-" + onset + "-" + label;
      const selectR = "#r-" + onset + "-" + offset + "-" + label;
      if (!self.selection.select(selectR).empty()) {
        self.selection.select(selectT).remove();
        self.selection.select(selectR).remove();
        self.$emit("delete-rect", this.channelName, end, start, selectG, label);

        self.undoActionStack.push(
          () => {
            self.drawRectangle(end, start, selectG, label, false);
          }
        );
      }
    },
    CtrlTrue() {
      // when the control button is clicked, it will raise the g.event-pointers on the top of the DOM hmtl elements.
      this.selection.select("g.events-pointers").raise();
    },
    CtrlFalse() {
      // when the control button is not clicked, it will raise either g.rectangles-events or g.rectangles-noise,
      // depending on the switch button that specifies if `EVENT` or `NOISE`
      if (this.annotationMode == "Events") {
        this.selection.select("g.rectangles-events").raise();
      } else {
        this.selection.select("g.rectangles-noise").raise();
      }
    },
    reDraw(data, resize=false) {
      // this function will crop the recording bsed on the task index
      // it will replot everything (lines, rectangles graphs ...)
      this.annotations = this.Export();
      var self = this;
      self.path.remove();
      self.selection.select("g.rectangles-noise").selectAll("rect").remove();
      self.selection.select("g.rectangles-noise").selectAll("text").remove();
      self.selection.select("g.rectangles-events").selectAll("rect").remove();
      self.selection.select("g.rectangles-events").selectAll("text").remove();
      self.selection.select("g.stims").selectAll("rect").remove();
      self.selection.select("g.pd-intervals").selectAll("rect").remove();
      self.selection.select("g.pd-detections").selectAll("rect").remove();
      self.selection.select("g.step").selectAll("rect").remove();
      self.selection.select("g.origin").selectAll("*").remove();
      self.selection.select("g.time-axis").selectAll("rect").remove();

      this.draw(data, resize);
    },
    drawStep() {
      // draws the stepsize lines, the direction of the line corresponds to the sign of the step size, and the length of the line is proportional
      // to the step size value. It binds the visibility style of the line with a boolean variable, so that it can be controled via a button in the
      // toolbox sidebar.
      const task_index = this.showTaskOnly ? this.indexTask : 0;
      for (const i in this.stims.stepSize) {
        let stimInfo = this.stims.stepSize[i];

        let x;
        let y;
        let height;

        if (stimInfo.step > 0) {
          x = stimInfo.index;
          y = this.height / 2;
          height = (stimInfo.step * (this.height / 2)) / 2;
        } else if (stimInfo.step < 0) {
          x = stimInfo.index;
          y = this.height / 2 - (-stimInfo.step * (this.height / 2)) / 2;
          height = (-stimInfo.step * (this.height / 2)) / 2;
        }
        this.selection
          .select("g.step")
          .append("rect")
          .style("fill", "#02000a")
          .attr("opacity", "1.")
          .attr("x", "" + this.xScale(x - task_index))
          .attr("y", "" + y)
          .attr("width", "" + 2)
          .attr("height", "" + height);
      }
      this.selection
        .select("g.step-axis")
        .attr("transform", "translate(" + 20 + ", 4)")
        .style("opacity", 0.6)
        .call(
          d3
            .axisLeft(
              d3
                .scaleLinear()
                .range([this.height - 8, 0])
                .domain([-2, 2])
            )
            .tickFormat(d3.format("d"))
            .ticks(3)
        );

      this.updateVisibility("g.step-axis", this.showStepSizes);
      this.updateVisibility("g.step", this.showStepSizes);
    },
    drawThreshold() {
      // draws the noise thresholds in red. The value corrresponds to `this.threshold`.
      this.upperThresh = this.selection
        .select("g.thresholds")
        .append("rect")
        .style("fill", "#BD2A2E")
        .attr("opacity", "0.7")
        .attr("x", "" + 0)
        .attr("y", "" + this.yScale(this.threshold))
        .attr("width", "" + this.width)
        .attr("height", "" + 1);
      this.downThresh = this.selection
        .select("g.thresholds")
        .append("rect")
        .style("fill", "#BD2A2E")
        .attr("opacity", "0.7")
        .attr("x", "" + 0)
        .attr("y", "" + this.yScale(-this.threshold))
        .attr("width", "" + this.width)
        .attr("height", "" + 1);
      this.updateVisibility("g.thresholds", this.showThreshold);
    },
    drawOrigin() {
      // draws the origin line, that represents the 0 baseline.
      this.origin = this.selection
        .select("g.origin")
        .append("rect")
        .style("fill", "#000000")
        .attr("opacity", "0.4")
        .attr("x", "" + 0)
        .attr("y", "" + this.yScale(0))
        .attr("width", "" + this.width)
        .attr("height", "" + 0.5);
    },
    drawStims() {
      // draws the stimilus (left/right/both blink and saccade) vertical lines in the `g.stims` element.
      // It also bind the visibility of the stims with a boolean variable that could be controled from the toolbox sidebar.
      const task_index = this.showTaskOnly ? this.indexTask : 0;
      for (const i in this.stims.rightBlink) {
        this.selection
          .select("g.stims")
          .append("rect")
          .style("fill", this.eventsColorMap["Blink-Right"])
          .attr("opacity", "0.7")
          .attr("x", "" + this.xScale(this.stims.rightBlink[i] - task_index))
          .attr("y", "" + 0)
          .attr("width", "" + 2)
          .attr("height", "" + this.height);
      }
      for (const i in this.stims.leftBlink) {
        this.selection
          .select("g.stims")
          .append("rect")
          .style("fill", this.eventsColorMap["Blink-Left"])
          .attr("opacity", "0.7")
          .attr("x", "" + this.xScale(this.stims.leftBlink[i] - task_index))
          .attr("y", "" + 0)
          .attr("width", "" + 2)
          .attr("height", "" + this.height);
      }
      for (const i in this.stims.bothBlink) {
        this.selection
          .select("g.stims")
          .append("rect")
          .style("fill", this.eventsColorMap["Blink-Both"])
          .attr("opacity", "0.7")
          .attr("x", "" + this.xScale(this.stims.bothBlink[i] - task_index))
          .attr("y", "" + 0)
          .attr("width", "" + 2)
          .attr("height", "" + this.height);
      }
      for (const i in this.stims.stepSize) {
        this.selection
          .select("g.stims")
          .append("rect")
          .style("fill", this.eventsColorMap["Saccade"])
          .attr("opacity", "0.7")
          .attr(
            "x",
            "" + this.xScale(this.stims.stepSize[i].index - task_index)
          )
          .attr("y", "" + 0)
          .attr("width", "" + 2)
          .attr("height", "" + this.height);
      }

      Object.keys(this.stims.others).forEach((stim) => {
        const color = this.get_color(stim);
        if (!this.eventsColorMap.hasOwnProperty(stim)) {
          "continue"
        }

        this.stims["others"][stim].forEach((idx) => {
          this.selection
            .select("g.stims")
            .append("rect")
            .style("fill", color)
            .attr("opacity", "0.7")
            .attr("x", "" + this.xScale(idx - task_index))
            .attr("y", "" + 0)
            .attr("width", "" + 2)
            .attr("height", "" + this.height);
        });
      });

      this.updateVisibility("g.stims", this.showStims);
    },

    get_color(str) {
      // a helper function that generates a color hexcode given a string value.
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = "#";
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }
      return colour;
    },
    updateVisibility(svg, value) {
      this.selection
        .select(svg)
        .style(
          "visibility",
          value ? "visible" : "hidden"
        );
    },
    drawLine(data) {
      // draws the timeseries data inside the `g.signal-line` elements.
      // .datum(data) : adds the data the path elements
      const path = this.selection
        .select("g.signal-line")
        .append("path")
        .datum(data)
        .attr("class", "line")
        .attr("stroke", "steelblue")
        .attr("stroke-width", "1.5")
        .attr("fill", "none")
        .attr("d", this.mainLine);

      return path;
    },
    resetScale() {
      // resets the scale of the plot, by resetitng the yScale domain to the initial scale, it also redaws the path element with
      // the initial scale. Resets the zoom to Identity, resets noise thresholds lines, and redraws the origin.
      this.yScale.domain([-this.initYScale, this.initYScale]);
      this.path.attr(
        "d",
        d3
          .line()
          .curve(d3.curveLinear)
          .x((d, i) => this.xScale(i))
          .y((d) => this.yScale(d))
      );
      this.YScale = this.initYScale;
      this.selection
        .select("g.events-pointers")
        .call(this.zoomDrag.transform, d3.zoomIdentity);
      this.upperThresh.attr("y", this.yScale(this.threshold));
      this.downThresh.attr("y", this.yScale(-this.threshold));
      this.origin.attr("y", this.yScale(0));
    },
    CTrue() {
      // event handlers, when called, modifies the yScale the wither zoom in with a `zoomStep` value.
      this.yScale.domain([
        -(1 + this.zoomStep) * this.YScale,
        (1 + this.zoomStep) * this.YScale,
      ]);
      this.path.attr(
        "d",
        d3
          .line()
          .curve(d3.curveLinear)
          .x((d, i) => this.xScale(i))
          .y((d) => this.yScale(d))
      );
      this.YScale = this.YScale * (1 + this.zoomStep);
      this.selection
        .select("g.events-pointers")
        .call(this.zoomDrag.transform, d3.zoomIdentity);
      this.upperThresh.attr("y", this.yScale(this.threshold));
      this.downThresh.attr("y", this.yScale(-this.threshold));
    },
    XTrue() {
      // event handlers, when called, modifies the yScale the wither  zoom out with a `zoomStep` value.
      this.path.attr(
        "d",
        d3
          .line()
          .curve(d3.curveLinear)
          .x((d, i) => this.xScale(i))
          .y((d) =>
            this.yScale.domain([
              -(1 - this.zoomStep) * this.YScale,
              (1 - this.zoomStep) * this.YScale,
            ])(d)
          )
      );
      this.YScale = this.YScale * (1 - this.zoomStep);
      this.selection
        .select("g.events-pointers")
        .call(this.zoomDrag.transform, d3.zoomIdentity);
      this.upperThresh.attr("y", this.yScale(this.threshold));
      this.downThresh.attr("y", this.yScale(-this.threshold));
    },
    drawPDIntervals(data) {
      // draws the peak data inside `g.pd-intervals`, and adds a text description in the bottom of the rectangle
      if (data == null) {
        if (this.peakData == null) {
          return;
        }
        data = this.peakData;
      } else {
        this.selection.select("g.pd-intervals").selectAll("rect").remove();
      }
      this.peakData = data;
      data.forEach((Info) => {
        const task_index = this.showTaskOnly ? this.indexTask : 0;
        const start = this.xScale(Info["onset"] - task_index);
        const end = this.xScale(Info["offset"] - task_index);

        this.selection
          .select("g.pd-intervals")
          .append("rect")
          .style("fill", "#151F30")
          .attr("stroke", "black")
          .attr("opacity", "0.2")
          .attr("stroke-opacity", 0)
          .attr("x", "" + start)
          .attr("y", "0")
          .attr("width", "" + end - start)
          .attr("height", "" + this.height)
          .attr("id", "r-" + Info["onset"] + "-" + Info["offset"] + "-peak")
          .datum({
            channel: this.channelName,
            onset: Info["onset"],
            offset: Info["offset"],
            description: "peak",
          });
      });
      this.updateVisibility("g.pd-intervals", this.showPDInterval);
    },
    drawPDDetections(data) {
      // draws the peak data inside `g.pd-intervals`, and adds a text description in the bottom of the rectangle
      // it also binds the visibility of the g.pd-intervals and g.pd-detections elements with a boolean variable, that is controllable from the sidebar.
      if (data == null) {
        if (this.peakDetections == null) {
          return;
        }
        data = this.peakDetections;
      } else {
        this.selection.select("g.pd-detections").selectAll("rect").remove();
      }
      this.peakDetections = data;
      data.forEach((detection) => {
        const task_index = this.showTaskOnly ? this.indexTask : 0;
        const start = this.xScale(detection - task_index);

        this.selection
          .select("g.pd-detections")
          .append("rect")
          .style("fill", this.eventsColorMap["PD"])
          .attr("opacity", "0.7")
          .attr("x", "" + start)
          .attr("y", "0")
          .attr("width", "" + 2)
          .attr("height", "" + this.height)
          .attr("id", "r-" + detection + "-det")
          .datum({
            channel: this.channelName,
            detection: detection,
            description: "detection",
          });
      });
      this.updateVisibility("g.pd-detections", this.showPDDetection);
    },
    Undo() {
      // the lastRect and lastText elements contain the last created recrangle and text DOM element in the current vuejs component,
      // and the .remove() deletes them
      if (this.undoActionStack.length > 0) {
        const undoAction = this.undoActionStack.pop();
        undoAction();
      }
    },
    Export() {
      // extracts the __data__ of each rectangle (onset, offset, annotation label) and adds it into an array
      // returns a dict of export results
      const annotations_events = [];
      const annotations_noise = [];

      this.selection
        .select("g.rectangles-noise")
        .selectAll("rect")
        ._groups[0].forEach(function (rect) {
          annotations_noise.push(rect.__data__);
        });
      this.selection
        .select("g.rectangles-events")
        .selectAll("rect")
        ._groups[0].forEach(function (rect) {
          annotations_events.push(rect.__data__);
        });

      return {
        events: annotations_events,
        noise: annotations_noise,
      };
    },
    // Method to clear SVG content
    clearChart() {
      d3.select('#svg-' + this.channelName).selectAll('*').remove();
    },
    // Method to update chart dimensions
    handleResize() {
      this.width = parseInt((window.innerWidth - 220) * 0.82);
      this.height = parseInt((window.innerHeight - 220) * 0.2);
      // this.clearChart();
      // this.draw();
      this.reDraw(null, true);
    },
    clearAnnots() {
      this.selection
        .select("g.rectangles-noise")
        .selectAll('*').remove();
      this.selection
        .select("g.rectangles-events")
        .selectAll('*').remove();
    },
  },
  mounted() {
    // when the component is mounted, it draws the mian lines and the annotations that come from the DB.
    this.draw();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    this.clearChart();
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped lang="scss">
#container-svg {
  margin: auto;
}
.div-channel-plot {
  border-radius: 8px;
  padding: 2px;
  margin-left: 0px;
  margin-right: 4px;
  border: 1px solid #b0aeaf;
}

#axis-left {
  float: left;
}
.container-plot {
  display: flex;
  flex-direction: row;
  margin: auto;
}
.scale {
  width: 10%;
  font-size: 0.85em;
  color: #13678a;
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: space-between;
}

</style>
