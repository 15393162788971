<template>
  <div class="main">
    <label for="datepick">
      <input
        type="date"
        :value="defaultDate"
        name="datepick"
        id="datepick"
        @input="$emit('update:modelValue', $event.target.value)">
    </label>
  </div>
</template>

<script>
export default {
  name: 'DatePicker',
  props: ['modelValue', 'defaultDate'],
  emits: ['update:modelValue'],
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
input[type="date"]{
    background-color: #7f90dec4;
    padding: 15px;
    font-family: "Roboto Mono",monospace;
    color: #ffffff;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 5px;
}
::-webkit-calendar-picker-indicator{
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}
h3 {
  font-family: "Trebuchet MS";
}
</style>
