<template>
  <v-card elevation="24">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Annotator Info</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model="email"
          :error-messages="errors"
          :success="valid"
          @keyup.enter="submit()"
          label="E-mail"
          required />
        <v-text-field
          v-model="password"
          :append-icon='hidePassword ? "mdi-eye-off" : "mdi-eye"'
          @click:append='() => (hidePassword = !hidePassword)'
          :type='hidePassword ? "password" : "text"'
          :error-messages="errors"
          :success="valid"
          @keyup.enter="submit()"
          label="Password"
          required />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="clear">Clear</v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        @click="submit()">
        Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    email: '',
    password: '',
    checkbox: '',
    valid: false,
    hidePassword: true,
    errors: '',
  }),
  emits: [
    'submitted',
  ],
  methods: {
    clear() {
      this.email = '';
      this.password = '';
      this.checkbox = '';
      requestAnimationFrame(() => {
        this.$refs.obs.reset();
      });
    },
    submit() {
      this.$emit('submitted', this.email, this.password);
    },
  },
};
</script>

<style>
</style>
