import { createApp } from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import loadFonts from './plugins/webfontloader';
import '@fortawesome/fontawesome-free/js/all';
import 'vuesax/dist/vuesax.css';
import './style.css';

loadFonts();

const app = createApp(App);

app.use(vuetify)
  .use(router)
  .use(store)
  .mount('#app');
