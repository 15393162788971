<script>
/* eslint-disable */
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selected: [],
            selectedChanels: '',
            open: false,
            openChan: false,
            optionsDict: {},
            channels: ['2', '4', '8', '14'],
            optionsDictChannel: {
                '2': false,
                '4': false,
                '8': false,
                '14': false,
            },
        }
    },
    methods : {
        handleOption(option) {
            if (this.optionsDict[option]) {
                const index = this.selected.indexOf(option);
                if (index > -1) {
                    this.selected.splice(index, 1);
                }
                this.optionsDict[option] = false;
            } else {
                this.selected.push(option);
                this.optionsDict[option] = true;
            }
        },
        handleOptionChannel(option) {
            if (this.optionsDictChannel[option]) {
                this.optionsDictChannel[option] = false;
                this.selectedChanels = '';
            } else {
                this.channels.forEach(element => this.optionsDictChannel[element] = false);
                this.optionsDictChannel[option] = true;
                this.selectedChanels = option;
            }
        },
        reset() {
            this.channels.forEach(element => this.optionsDictChannel[element] = false);
            this.options.forEach(element => this.optionsDict[element] = false);
            this.selectedChanels = '';
            this.selected = [];
        },
    },
    emits: [
        'filter',
    ],
    created() {
        this.options.forEach(element => this.optionsDict[element] = false);
    }
}
</script>

<template>
    <div class="main">

        <div class="select-container">
            <div class="custom-select" :tabindex="tabindex" @blur="open = false">
                <div class="selected" :class="{ open: open }" @click="open = !open"
                @Keydown="open = !open">
                    <span class="span-filer">Filter tasks</span>
                </div>

                <div
                class="items" :class="{ selectHide: !open }"
                >
                    <div
                    v-for="(option, i) of options"
                    :key="i"
                    @click="
                        handleOption(option);
                        open = false;
                        $emit('filter', selected, selectedChanels);
                    "
                    @Keydown="
                        handleOption(option);
                        open = false;
                        $emit('filter', selected, selectedChanels);
                    "
                    :class="{ checkedItem: optionsDict[option] }"
                    >
                    {{ option }}
                    </div>
                </div>
            </div>

            <div id="channels" class="custom-select" :tabindex="tabindex" @blur="openChan = false">
                <div class="selected" :class="{ openChan: openChan }" @click="openChan = !openChan"
                @Keydown="openChan = !openChan">
                    <span class="span-filer">Filter channels</span>
                </div>

                <div
                class="items" :class="{ selectHide: !openChan }"
                >
                    <div
                    v-for="(option, i) of channels"
                    :key="i"
                    :class="{ checkedItem: optionsDictChannel[option] }"
                    @click="
                        handleOptionChannel(option);
                        openChan = false;
                        $emit('filter', selected, selectedChanels);
                    "
                    @Keydown="
                        handleOptionChannel(option);
                        openChan = false;
                        $emit('filter', selected, selectedChanels);
                    "
                    >
                    {{ option }}
                    </div>
                </div>
            </div>

            <div class="reset">
                <div
                @click="
                reset();
                $emit('filter', selected, selectedChanels);"
                @keydown="
                reset();
                $emit('filter', selected, selectedChanels);"
                class="icon-reset"
                >
                    <i class="fa-solid fa-filter-circle-xmark fa-xl"></i>
                </div>
            </div>
        </div>

        <div class="filter-items-container">
            <div
            v-for="(select, i) of selected"
            :key="i"
            >
            <span class="span-select">{{ select }}</span>
            </div>
            <div v-if="selectedChanels.length !=0">
                <span class="span-select">{{ selectedChanels }} channels</span>
            </div>
        </div>
    </div>
</template>

<style>
.reset {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1%;
}

.icon-reset {
    color: #7b8b9a; /* Light shade of #0c526a */
    margin-top: 20%;
}

.icon-reset:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.icon-reset:active {
    color: #4b5a6e; /* Darker shade of #0c526a */
}

.select-container {
    display: flex;
    flex-direction: row;
}

#channels {
    width: 40%;
    margin-left: 2%;
}

.checkedItem {
    background-color: #1a3a5f; /* Darker shade of #0c526a */
}

.span-select {
    padding: 2px;
    margin: 3px;
    background-color: #a0b9c9; /* Lighter shade of #0c526a */
    border-radius: 5px;
    cursor: default;
}

.filter-items-container {
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-top: 10px;
    overflow-y: hidden;
    max-height: 80vh;
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    transition: scrollbar-color 0.9s ease-out;
}

.filter-items-container:hover {
    overflow-y: auto;
}

.filter-items-container::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
}

.filter-items-container::-webkit-scrollbar-thumb {
    background-color: #5a6a77; /* Medium shade of #0c526a */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 5px solid transparent; /* creates padding around scroll thumb */
    background-clip: content-box;
}

.span-filer {
    font-weight: bold;
}

.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 30px;
    line-height: 30px;
}

.custom-select .selected {
    background-color: #0c526a; /* Base color */
    border-radius: 10px;
    border: 2px solid #1a3a5f; /* Darker shade of #0c526a */
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

.custom-select .selected.open {
    border: 2px solid #7b8b9a; /* Lighter shade of #0c526a */
    border-radius: 10px 10px 0px 0px;
}

.custom-select .selected.openChan {
    border: 2px solid #7b8b9a; /* Lighter shade of #0c526a */
    border-radius: 10px 10px 0px 0px;
}

.custom-select .selected:after {
    position: absolute;
    content: "";
    top: 16px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #fff transparent transparent transparent;
}

.custom-select .items {
    color: #fff;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    border-right: 2px solid #7b8b9a; /* Lighter shade of #0c526a */
    border-left: 2px solid #7b8b9a; /* Lighter shade of #0c526a */
    border-bottom: 2px solid #7b8b9a; /* Lighter shade of #0c526a */
    position: absolute;
    background-color: #0c526a; /* Base color */
    left: 0;
    right: 0;
    z-index: 1;
}

.custom-select .items div {
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

.custom-select .items div:hover {
    background-color: #1a3a5f; /* Darker shade of #0c526a */
}

.selectHide {
    display: none;
}
</style>