<template>
  <div class="container">
    <button type="button" class="btn btn-primary">{{ msg }}</button>
  </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios';

export default {
  name: 'PingTest',
  data() {
    return {
      msg: 'Hello!',
    };
  },
  methods: {
    getMessage() {
      const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/ping';
      console.log(path);
      axios.get(path).then((res) => {
        this.msg = res.data;
      })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
      //this.getMessage();
  },
};
</script>
