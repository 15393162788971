<template>
  <div class="container-svg">
    <div class="scale-container">
      <div>
        {{ 2 * Math.round(YScale)}} μV
      </div>
    </div>
    <div>
      <svg :id="'svg-ica-' + channelName" class="div-channel-plot">
        <g class="signal-line" />
        <g class="time-axis" />
        <g class="stims" />
        <g class="rectangles-ica" />
        <g class="event-pointers" />
      </svg>
    </div>
    <div class="name-container">
      <div class="channelName">
        {{channelName}}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
/* eslint prefer-template: "off" */

import * as d3 from 'd3';

export default {
  name: 'contextICA',
  components: {
  },
  emits : ['reset-last', 'draw-rect', 'delete-rect'],
  props: {
    timeseries: Array,
    channelName: String,
    checkedICs: Array,
    sfreq: Number,
    stims: Object,
    annot: Object,
  },
  data() {
    return {
      width: 1500,
      height: 75,

      xScale: null,
      yScale: null,

      selection: null,
      path: null,
      mainLine: null,
      brush: null,

      lastRect: null,

      initYScale: 8,
      YScale: 8,
      zoomStep: 0.1,

      events: ['Blink-Both', 'Blink-Right', 'Blink-Left', 'Saccade'],
      colormap: {'Blink-Both' : '#02A676', 'Blink-Right' : '#FF9933', 'Blink-Left' : '#A6033F', 'Saccade' : '#6958A6'},
    };
  },
  methods: {
    draw() {
      this.xScale = d3.scaleLinear().domain([0, this.timeseries.length]).range([0, this.width]);
      this.yScale = d3.scaleLinear().domain([-this.initYScale, this.initYScale]).range([this.height, 0]);

      // Line
      this.mainLine = d3.line()
        .curve(d3.curveLinear)
        .x((d, i) => this.xScale(i))
        .y((d) => this.yScale(d));

      // Selection
      this.selection = d3.select('#svg-ica-' + this.channelName);

      this.selection.attr('width', this.width)
        .attr('height', this.height+5)
        .attr('viewBox', '0 0 ' + this.width + ' ' + this.height);

      this.path = this.drawLine(this.timeseries);

      // Brush
      this.brush = d3.brushX().filter(this.filterBrush)
        .extent([[0, 0], [this.width, this.height]])
        .on('end', this.handleBrush);

      this.selection.select('g.event-pointers')
        .call(this.brush)
        .call(this.enableDefaultCursor);

        this.drawStims();
        this.drawtimeScale();

    },
    drawAnnot() {
      for (const annotType in this.annot) {
        const annotInfo = this.annot[annotType];

        var self = this;
        
        const selectG = 'g.rectangles-ica';

        self.selection.select(selectG).append('rect').style('fill', '#BD2A2E')
            .attr('stroke', 'black')
            .attr('opacity', '0.3')
            .attr('stroke-opacity', 0)
            .attr('x', '' +  Math.round(this.xScale(annotInfo['onset'])))
            .attr('y', '0')
            .attr('width', '' + Math.round(this.xScale(annotInfo['offset'])) - Math.round(this.xScale(annotInfo['onset'])))
            .attr('height', '' + this.height)
            .attr('id', 'r-' + Math.round(this.xScale(annotInfo['onset'])) + '-' + Math.round(this.xScale(annotInfo['offset'])))
            .datum({
              channel: this.channelName,
              onset : annotInfo['offset'],
              offset:annotInfo['offset'],
              description: 'bad_segment'
            })
            .on('mouseover', function () {
            })
            .on('mousemove', function () {
            })
            .on('mouseleave', function() {
            })
            .on('click', function (e) {
              if (e.shiftKey) {
                const this_ = d3.select(this);
                const selectR = '#r-' + this_.attr('x') + '-' + (parseInt(this_.attr('x')) + parseInt(this_.attr('width')));
                self.$emit('delete-rect', this.channelName, selectR);
                this_.remove();
              }
            });
      };
    },
    drawLine(data) {
      const path = this.selection.select('g.signal-line')
        .append('path').datum(data).attr('class', 'line')
        .attr('stroke', 'steelblue')
        .attr('stroke-width', '1.5')
        .attr('fill', 'none')
        .attr('d', this.mainLine);
      
        return path
    },
    drawtimeScale() {
      this.selection.select('g.time-axis')
        .style("font-size", "9px")
        .call(d3.axisBottom(d3.scaleLinear().domain([0, this.timeseries.length / this.sfreq]).range([0, this.width]))
        .tickSize(3).tickFormat(d => (d + "s")));
      
    },
    drawStims() {
      for (const i in this.stims.rightBlink){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Blink-Right'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.rightBlink[i]))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      for (const i in this.stims.leftBlink){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Blink-Left'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.leftBlink[i]))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      for (const i in this.stims.bothBlink){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Blink-Both'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.bothBlink[i]))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      for (const i in this.stims.stepSize){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Saccade'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.stepSize[i].index))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      this.selection.select('g.stims').style("visibility", function() {
          return this.showStims ? "visible" : "hidden";
      });
    },
    toggleStims() {
      console.log('stims');
      this.selection.select('g.stims').style("visibility", function() {
            this.showStims = !this.showStims;
            return this.showStims ? "visible" : "hidden";
        });
    },
    filterBrush(event) {
      return event.ctrlKey;
    },
    enableDefaultCursor(g) {
      return g.select('.overlay').style('cursor', 'default');
    },
    handleBrush(event) {
      if (event.selection != null) {
        if (Math.abs(event.selection[0] - event.selection[1]) >= 1) {
          let start;
          let end;
          if (event.selection[0] < event.selection[1]) {
            start = Math.round(event.selection[0]);
            end = Math.round(event.selection[1]);
          } else {
            start = Math.round(event.selection[1]);
            end = Math.round(event.selection[0]);
          }

          // Rectangle
          const selectG = 'g.rectangles-ica';

          if (this.checkedICs.length > 0) {
            this.$emit('draw-rect', this.channelName, selectG, end, start);
          };

          this.drawRectangle(end, start, selectG);
          this.selection.select('g.event-pointers')
            .call(this.brush.move, null);
        }
      }
    },
    drawRectangle(end, start, selectG) {
      var self = this;
      this.lastRect = self.selection.select(selectG).append('rect').style('fill', '#BD2A2E')
            .attr('stroke', 'black')
            .attr('opacity', '0.3')
            .attr('stroke-opacity', 0)
            .attr('x', '' + start)
            .attr('y', '0')
            .attr('width', '' + end - start)
            .attr('height', '' + this.height)
            .attr('id', 'r-' + start + '-' + end)
            .datum({
              channel: this.channelName,
              onset : Math.round(this.xScale.invert(start)),
              offset: Math.round(this.xScale.invert(end)),
              description: 'bad_segment'
            })
            .on('mouseover', function () {
            })
            .on('mousemove', function () {
            })
            .on('mouseleave', function() {
            })
            .on('click', function (e) {
              if (e.shiftKey) {
                const this_ = d3.select(this);
                const selectR = '#r-' + this_.attr('x') + '-' + (parseInt(this_.attr('x')) + parseInt(this_.attr('width')));
                self.$emit('delete-rect', this.channelName, selectR);
                this_.remove();
              }
            });
      this.$emit('reset-last', this.channelName);
    },
    Undo() {
      if (this.lastRect) {
        this.lastRect.remove();
      }
    },
    Inverse() {
      const annotations = [];

      this.selection.select('g.rectangles-ica').selectAll('rect')._groups[0].forEach( function(rect) {
        annotations.push(rect.__data__);
      });
      return annotations
    },
    CtrlTrue() {
      this.selection.select('g.event-pointers').raise();
      this.selection.select('g.event-pointers').select('.overlay').style('cursor', 'crosshair');
    },
    CtrlFalse() {
      this.selection.select('g.event-pointers').select('.overlay').style('cursor', 'default');
      this.selection.select('g.rectangles-ica').raise();
    },
    CTrue() {
      this.yScale.domain([- (1 + this.zoomStep) * this.YScale, (1 + this.zoomStep) * this.YScale]);
      this.path.attr('d', d3.line()
        .curve(d3.curveLinear)
        .x((d, i) => this.xScale(i))
        .y((d) => (this.yScale(d))));
      this.YScale = this.YScale * (1 + this.zoomStep);
    },
    XTrue() {
      this.path.attr('d', d3.line()
        .curve(d3.curveLinear)
        .x((d, i) => this.xScale(i))
        .y((d) => (this.yScale.domain([- (1 - this.zoomStep) * this.YScale, (1 - this.zoomStep) * this.YScale])(d))));
      this.YScale = this.YScale * (1 - this.zoomStep);
    },
  },
  mounted() {
    this.draw();
    this.drawAnnot();
  },
  ready: function() {
    console.log('my-component ready');
  },
  destroyed: function() {
    console.log('my-component destroyed');
  }
};
</script>

<style scoped lang="scss">
.container-svg {
  display: flex;
  flex-direction : row;
  align-items: center;
  justify-content: center;
}
.div-channel-plot{
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #B0AEAF;
}
.scale-container {
  border: 1px solid #B0AEAF;
}
.channelName {
  margin-left: 10px;
}
.check-ica {
  margin-left: 20px;
}
</style>
