<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background-color: white;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.container {
  display: inline-block;
  padding-top: 75px !important;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}
</style>
