<template>
  <div class="main">
    <div class="datedate">
      <div>
        <div class="back-users">
          <div class="back-back-users">
            <span
              class="back-back-back-users"
              @click="goToHome"
              @keydown="goToHome">
              <i class="fa-solid fa-chevron-left" /> <span class="span-users">back</span>
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="container-date">
        <div>
          <div class="from">
            <DatePicker v-model="dateFrom" :defaultDate="dateFrom" />
          </div>
        </div>
        <div>
          <div class="to">
            <DatePicker v-model="dateTo" :defaultDate="dateTo" />
          </div>
        </div>
      </div> -->
    </div>

    <div class="users-container">
      <div class="search-container">
        <div class="search">
          <v-text-field
            v-model="match"
            label="search"
          />
        </div>

        <div class="filter-container">
          <filterComponent
            :options="Alltasks"
            @filter="onFilter"
          />
        </div>
      </div>

      <div class="table-container">
        <TableGrid
          :data="users"
          :keys="keys"
          :columns="header"
          :filter-key="match"
          @clicked="(row) => rowClicked(row)" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import TableGrid from './TableGrid.vue';
import DatePicker from '@/components/DatePicker.vue';
import filterComponent from '@/components/filterComponent.vue';
import axios from 'axios';
import qs from 'qs';

export default {
  data() {
    return {

      match: '',
      users: [],
      keys: [
        'user_id',
        'date',
        '#recordings',
        '#annotated',
        '#approvals',
        'snr_average',
        'last_annotator'
      ],
      header: [
        'User ID',
        'Date Joined',
        '#Recordings',
        '#Annotations',
        '#Approvals',
        'SNR Average',
        'Last Annotator'
      ],
      selectedUser: '',
      dateFrom: '2020-01-01',
      dateTo: this.getCurrentDate(),
      Alltasks: [],
      selectedTasks: [],
      selectedChannel: '',
      username: this.$store.state.username,
      token: this.$store.state.token,
      role: this.$store.state.role,
    };
  },
  components: {
    TableGrid,
    DatePicker,
    filterComponent,
  },
  watch: {
    dateFrom() {
      if (this.dateFrom!='' && this.dateTo!='') {
        this.getUserIds();
      }
    },
    dateTo() {
      if (this.dateFrom!='' && this.dateTo!='') {
        this.getUserIds();
      }
    }
  },
  methods: {
    onFilter(optionsTasks, optionsChannels) {
      this.selectedTasks = optionsTasks;
      this.selectedChannel = optionsChannels;

      this.getUserIds();

    },
    getCurrentDate: function() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth()+1+'').padStart(2, "0")
      const day = (date.getDate()+'').padStart(2, "0")
      const currentDate = year+"-"+month+"-"+day
      return currentDate
    },
    customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
          if (index[0]=='date') {
            if (!isDesc[0]) {
                return new Date(b[index]) - new Date(a[index]);
            } else {
                return new Date(a[index]) - new Date(b[index]);
            }
          }
          else {
            if(typeof a[index] !== 'undefined'){
              if (!isDesc[0]) {
                 return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
              }
              else {
                  return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
              }
            }
          }
      });
      return items;
    },
    goToRecordings() {
      this.$router.push({
        name: 'recordings',
        params: {
          user: this.selectedUser,
        }
      })
    },
    goToHome() {
      this.$router.push({
        name: 'home',
      })
    },
    getAllTasks() {
        const options = {
            headers: {'Authorization': 'Bearer ' + this.token}
        };
        const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/tasks';
        axios.get(path, options)
        .then(res => {
          this.Alltasks = res.data['tasks'];
        })
          .catch(error => {
            console.error(error);
            this.$router.push({
                name: 'home',
            })
          });
    },
    getUserIds() {
      const options = {
          headers: {'Authorization': 'Bearer ' + this.token},
          params: {
            date_from: this.dateFrom,
            date_to: this.dateTo,
            selectedTasks: this.selectedTasks,
            selectedChannel: this.selectedChannel,
          },
          paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
      };
      const path = location.protocol + '//' + location.hostname + process.env.VUE_APP_API_ENDPOINT + '/users';
      axios.get(path, options)
      .then(res => {
        this.users = Object.values(res.data);
      })
        .catch(error => {
          console.error(error);
          this.$router.push({
            name: 'home',
          })
        });

    },
    rowClicked(row) {
      this.selectedUser = row['user_id'];
      this.goToRecordings();
    },
  },
  created() {
    this.getAllTasks();
    this.getUserIds();
  },
  computed: {
  },
};
</script>

<style scoped>
.users-container{
  display: flex;
  flex-direction: column;
  width: 80%;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  align-items: center;
}
.filter-container {
  width: 100%;
}
.search-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 50%;
}

.search{
  width: 100%;
}
.back-users {
  margin-right: 80%;
  height: 30px;
}
.back-back-users {
  margin-right: 60%;
  align-items: center;
  justify-content: center;
}
.datedate {
  margin-bottom: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.container-date{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.from {
  margin-right: 20px;
}
.to {
  margin-left: 20px;
}

#button{
  margin-top: 30px;
}

.span-users {
  margin-left: 10px;
  text-decoration: underline;
}
.back-back-back-users:hover  {
  cursor: pointer;
}
</style>
