<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<template>
  <Transition name="ChoiceModal">
    <div v-if="show" class="ChoiceModal-mask">
      <div class="ChoiceModal-wrapper">
        <div class="ChoiceModal-container">
          <div class="ChoiceModal-header">
            <slot name="header">default header</slot>
          </div>

          <div class="ChoiceModal-body">
            <slot name="body">default body</slot>
          </div>

          <div class="ChoiceModal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.ChoiceModal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.ChoiceModal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.ChoiceModal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #32383D;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.ChoiceModal-header{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.ChoiceModal-body{
  display: flex;
  justify-content: center;
  align-items: center;
}
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.ChoiceModal-enter-from {
  opacity: 0;
}

.ChoiceModal-leave-to {
  opacity: 0;
}

.ChoiceModal-enter-from .ChoiceModal-container,
.ChoiceModal-leave-to .ChoiceModal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
