<template>
  <div id="back">
    <div
      class="popup"
      v-show="showPopup"
      :style="{ top: popupPosition.y + 'px', left: popupPosition.x + 'px' }">
      Copied to clipboard!
    </div>
    <div id="content-labeler">
      <span class="titles">
        User ID | <b id="userId"> {{ user }} </b>
        <span
          @click="copyToClipboard($event, 'userId')"
          @keydown="copyToClipboard($event, 'userId')">
          <b class="fa-regular fa-copy copy-icon" />
        </span>
      </span>
      <span class="titles">
        Rec ID | <b id="recId"> {{ rec }} </b>
        <span
          @click="copyToClipboard($event, 'recId')"
          @keydown="copyToClipboard($event, 'recId')">
          <b class="fa-regular fa-copy copy-icon" />
        </span>
      </span>
      <span class="titles">
        Task | <b> {{ task }} </b>
      </span>
      <span class="titles">
        Device | <b> {{ device }} </b>
      </span>
      <span class="titles">
        Notes | <b> {{ notes }} </b>
      </span>
      <span class="snr-container" v-if="snr">
        SNR | <b> {{ snr.toFixed(2) }} </b>
      </span>
      <span class="snr-container" v-else>
        SNR | <b>N/C</b>
      </span>
      <div class="checkbox-all-channels">
        <span class="checkbox-all">
          <label for="checkAll">
            <input
              type="checkbox"
              id="checkAll"
              v-model="this.isCheckAll"
              @click="checkAll()"
            /> All </label>
        </span>
      </div>
      <div id="contextdiv">
        <div v-for="(x, name, index) of this.request.channels" :key="index">
          <div>
            <div id="container">
              <div class="content">
                <ContextContent
                  v-if="x.length != 0"
                  :eventsColorMap="eventsColorMap"
                  :noiseColorMap="noiseColorMap"
                  :selectedEventLabel="selectedEventLabel"
                  :selectedNoiseLabel="selectedNoiseLabel"
                  :annotationMode="annotationMode"
                  :timeseries="x"
                  :annot="this.request.annotations[name]"
                  :channelName="name"
                  :stims="this.request.stims"
                  :checkedChannels="this.checkedChannels"
                  :sfreq="sfreq"
                  :indexTask="this.request.task_index"
                  :showStims="showStims"
                  :showStepSizes="showStepSizes"
                  :showEventAnnots="showEventAnnots"
                  :showNoiseAnnots="showNoiseAnnots"
                  :showTaskOnly="showTaskOnly"
                  :showThreshold="showThreshold"
                  :showPDDetection="showPDDetection"
                  :showPDInterval="showPDInterval"
                  :ref="name"
                  @delete-rect="deleteRect"
                  @draw-rect="drawRect"
                />
              </div>
              <div class="stats-container">
                <div class="channel-snr-container" v-if="channelsSNR[name]">
                  <div>SNR<br><strong>{{ channelsSNR[name].toFixed(2) }}</strong> dB</div>
                </div>
                <div class="channel-snr-container" v-else>
                  <div>SNR<br><strong>N/C</strong></div>
                </div>
                <div class="channel-rms-container" v-if="channelsRMS['floor']['channels'][name]">
                  <div>RMS<br>{{ channelsRMS['floor']['channels'][name].toFixed(2) }} &#956;V</div>
                </div>
                <div class="channel-rms-container" v-else>
                  <div>RMS<br>N/C</div>
                </div>
              </div>
              <div class="check">
                <span class="check-span">
                  <label :for="name">
                    <input
                      type="checkbox"
                      :id="name"
                      v-model="checkedChannels"
                      v-bind:value="name"
                      @change="updateCheckall()"
                    />
                    {{ name }}
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <modalExport :show="showModalExport" @close="showModalExport = false">
        <template #header>
          <h3>Please choose an option</h3>
        </template>
        <template #body>
          <div class="container-btns">
            <div class="modal-seperator-items" />
            <div
              class="modal-export-btns"
              @click="showModalExport = false"
              @keydown="showModalExport = false"
            >
              <div class="modal-editor-icon">
                <div class="modal-icon">
                  <i class="fa-solid fa-arrow-left-long" />
                </div>
              </div>
              <div>
                <span>Continue Labeling</span>
              </div>
            </div>
            <div class="modal-seperator-items" />
            <div
              class="modal-export-btns"
              @click="newRecord"
              @keydown="newRecord"
            >
              <div class="modal-editor-icon">
                <div class="modal-icon">
                  <i class="fa-solid fa-circle-plus" />
                </div>
              </div>
              <div>
                <span>Choose a new recording</span>
              </div>
            </div>
          </div>
        </template>
      </modalExport>
    </Teleport>

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <modalComments
        :show="showModalComments"
        @close="showModalComments = false"
        ref="modalComments"
      >
        <template #header>
          <div class="header-cmnt">
            <h3>Add comments</h3>
          </div>
          <div
            class="icon-clone-cmnt"
            @click="showModalComments = false"
            @keydown="showModalComments = false"
          >
            <i class="fa-solid fa-xmark fa-xl" />
          </div>
        </template>
        <template #body>
          <div class="container-comments">
            <div v-for="(x, name, index) of this.request.channels" :key="index">
              <div class="container-name-cnmt">
                <div class="name-chan-cont">
                  <div class="name-chan">
                    {{ name }}
                  </div>
                </div>
                <div>
                  <label :for="'cmnt-' + name">
                    <textarea
                      :id="'cmnt-' + name"
                      v-model="comments[name]"
                      placeholder="add comments"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </modalComments>
    </Teleport>

    <Teleport to="body">
      <div ref="helpModal">
        <helpModal :show="showModalHelp" @close="showModalHelp = false">
          <template #body>
            <div class="container-comments">
              <!-- Use v-html to render the HTML generated by marked -->
              <div v-html="renderedMarkdown" class="markdown-content" />
            </div>
          </template>
        </helpModal>
      </div>
    </Teleport>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { marked } from "marked";
import ContextContent from "@/components/labeler/ContextContent.vue";
import modalExport from "@/components/labeler/modalExport.vue";
import modalComments from "@/components/labeler/modalComments.vue";
import helpModal from "@/components/labeler/helpModal.vue";


axios.defaults.withCredentials = true;

export default {
  name: "MainLabeler",
  props: ["user", "rec", "token", "task", "device", "notes",
          "eventsColorMap", "noiseColorMap", "selectedEventLabel", "selectedNoiseLabel", "annotationMode",
          "showStims", "showStepSizes", "showEventAnnots", "showNoiseAnnots",
          "showThreshold", "showTaskOnly", "showPDDetection", "showPDInterval"],
  emits: ["check-peak", "update-events"],
  components: {
    ContextContent,
    modalExport,
    modalComments,
    helpModal,
  },
  data() {
    return {
      request: {},
      annotations: {},
      sfreq: null,
      snr: null,
      channelsSNR: {},
      channelsRMS: {},

      checkedChannels: [],
      isCheckAll: false,

      showModalExport: false,
      showModalComments: false,
      showModalHelp: false,

      showPopup: false,
      popupPosition: { x: 0, y: 0 },
      comments: {},

      renderedDocumentation: '',
    };
  },
  methods: {
    updateMousePosition(event) {
      this.popupPosition = { x: event.clientX, y: event.clientY };
    },
    copyToClipboard(event, elementId) {
      var text = document.getElementById(elementId).innerText;
      navigator.clipboard.writeText(text);

      // Get mouse coordinates
      this.updateMousePosition(event);

      this.showPopup = true;
      setTimeout(() => {
        this.showPopup = false;
      }, 1000); // Set the duration for the popup to be visible
    },
    getdata() {
      // sends request to the backend and retrieves data, then saves the result in the corresponding variables.
      const options = {
        headers: { Authorization: "Bearer " + this.token },
      };
      const path =
        location.protocol +
        "//" +
        location.hostname +
        process.env.VUE_APP_API_ENDPOINT +
        "/recordings/" +
        this.user +
        "/" +
        this.rec +
        "/data";
      axios.get(path, options).then((res) => {
        this.request = res.data;
        this.comments = this.request.comments;
        this.sfreq = this.request.sfreq;
        if (!this.isCheckAll) {
          this.checkAll();
        }
      })
      .catch((error) => {
        console.error(error);
        this.$router.push({
          name: "home",
        });
      });

    },
    handleDocumentClick(event) {
      // Check if the clicked element is outside the modal
      if (
        this.showModalHelp &&
        this.$refs.helpModal && // Check if ref is available
        this.$refs.helpModal.contains(event.target)
      ) {
        // Close the modal
        this.showModalHelp = false;
      }
    },
    checkAll() {
      // to check all the boxes.
      this.isCheckAll = !this.isCheckAll;
      this.checkedChannels = [];
      if (this.isCheckAll) {
        for (var key in this.request.channels) {
          this.checkedChannels.push(key);
        }
      }
    },
    updateCheckall() {
      // to handle all the checkboxes
      if (this.checkedChannels.length == Object.keys(this.request.channels).length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    applySampling(minFreq, maxFreq, median, diff, accel, accelxvel) {
      // send a request to the backend to retrieve resampled data, then redraws the plots with new timeseries data
      const options = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
        params: {
          processing: {
            filter: [minFreq, maxFreq],
            median: median,
            diff: diff,
            accel: accel,
            accelXvel: accelxvel,
          }
        }
      };
      const path =
        location.protocol +
        "//" +
        location.hostname +
        process.env.VUE_APP_API_ENDPOINT +
        "/recordings/" +
        this.user +
        "/" +
        this.rec +
        "/filter";
      axios.get(path, options).then((res) => {
        for (const chan in this.request.channels) {
          this.$refs[chan][0].reDraw(res.data[chan]);
        }
      });
    },
    resetScale() {
      // resets the scale
      for (const chan in this.request.channels) {
        this.$refs[chan][0].resetScale();
      }
    },
    choiceModel(seletedModel) {
      this.seletedModel = seletedModel;
    },
    calculateRMS(from_source=false) {
      // sends a request to the backend to get the values of the calculated SNR, then updates the value inside the contextContent.vue
      // component
      const events = [];
      for (const chan in this.request.channels) {
        const export_ = this.$refs[chan][0].Export();
        events.push(...export_["events"]);
      }

      const options = {
        headers: { Authorization: "Bearer " + this.token },
      };

      const path =
        location.protocol +
        "//" +
        location.hostname +
        process.env.VUE_APP_API_ENDPOINT +
        "/recordings/" +
        this.user +
        "/" +
        this.rec +
        "/rms";

      axios
        .post(
          path,
          {
            events: events,
            from_source: from_source,
          },
          options
        )
        .then((res) => {
          this.channelsRMS = res.data;
        });
    },
    calculateSNR(from_source=false) {
      // sends a request to the backend to get the values of the calculated SNR, then updates the value inside the contextContent.vue
      // component
      const events = [];
      for (const chan in this.request.channels) {
        const export_ = this.$refs[chan][0].Export();
        events.push(...export_["events"]);
      }

      const options = {
        headers: { Authorization: "Bearer " + this.token },
      };

      const path =
        location.protocol +
        "//" +
        location.hostname +
        process.env.VUE_APP_API_ENDPOINT +
        "/recordings/" +
        this.user +
        "/" +
        this.rec +
        "/snr";

      axios
        .post(
          path,
          {
            events: events,
            from_source: from_source,
          },
          options
        )
        .then((res) => {
          this.snr = res.data['total'];
          this.channelsSNR = res.data['channels'];
        });
    },
    CTrue() {
      for (const chan in this.request.channels) {
        this.$refs[chan][0].CTrue();
      }
    },
    XTrue() {
      for (const chan in this.request.channels) {
        this.$refs[chan][0].XTrue();
      }
    },
    CtrlTrue() {
      for (const chan in this.request.channels) {
        this.$refs[chan][0].CtrlTrue();
      }
    },
    CtrlFalse() {
      for (const chan in this.request.channels) {
        this.$refs[chan][0].CtrlFalse();
      }
    },
    drawRect(name, end, start, selectG, label) {
      // drwas the rectangle inside the context content component
      for (const chan in this.request.channels) {
        if (this.checkedChannels.includes(chan)) {
          this.$refs[chan][0].drawRectangle(end, start, selectG, label);
        }
      }
      this.calculateSNR();
      this.calculateRMS();
    },
    deleteRect(name, end, start, selectG, label) {
      // it selects the corresponding rectangle and text, then deletes them
      for (const chan in this.request.channels) {
        if (name != chan) {
          if (this.checkedChannels.includes(chan)) {
            this.$refs[chan][0].removeRectangle(end, start, selectG, label);
          }
        }
      }
      this.calculateSNR();
      this.calculateRMS();
    },
    openEditor() {
      this.showModalComments = true;
    },
    Undo() {
      // applies the undo function inside the context content component
      for (const chan in this.request.channels) {
        this.$refs[chan][0].Undo();
      }
      this.calculateSNR();
      this.calculateRMS();
    },
    Export() {
      // exports the annotation data (onset, offset, label) and send a request to the backend in orer to update the annotations in the DB
      const events = [];
      const noise = [];
      for (const chan in this.request.channels) {
        const export_ = this.$refs[chan][0].Export();

        events.push(...export_["events"]);
        noise.push(...export_["noise"]);
      }

      const data = {
        events: events,
        noise: noise,
        comments: this.comments,
      }
      return data;
    },
    newRecord() {
      this.$router.push({
        name: "users",
      });
    },
    clearAnnots() {
      for (const chan in this.request.channels) {
        this.$refs[chan][0].clearAnnots();
      }
    },
    toggleModel() {
      // toggle the model prediction retangles
      for (const chan in this.request.channels) {
        this.$refs[chan][0].toggleModel();
      }
    },
    togglePeaks() {
      // toggle the model peaks retangles
      for (const chan in this.request.channels) {
        this.$refs[chan][0].togglePeaks();
      }
    },
    togglePdModel() {
      // toggle the model pd model retangles
      for (const chan in this.request.channels) {
        this.$refs[chan][0].togglePdModel();
      }
    },
    toggleHelp() {
      this.showModalHelp = true;
    },
    toggleTaskOnly() {
      this.showTaskOnly = !this.showTaskOnly;
    },
    applyPD(sensitivity) {
      // sends a reqeust to the backend to apply the PD algorithm and return the indexes of the peaks with the corresponding label
      const options = {
        headers: { Authorization: "Bearer " + this.token },
        params: {
          "threshold_coeff": sensitivity,
        }
      };
      const path =
        location.protocol +
        "//" +
        location.hostname +
        process.env.VUE_APP_API_ENDPOINT +
        "/models/pd/" +
        this.user +
        "/" +
        this.rec;
      axios.get(path, options).then((res) => {
        for (const chan in this.request.channels) {
          this.$refs[chan][0].drawPDIntervals(res.data.peaks[chan]);
          this.$refs[chan][0].drawPDDetections(res.data.detections);
        }
        this.$emit("check-peak");
      }).catch((error) => {
        console.error('Error fetching data:', error);
      });
    },
    loadMarkdownFile() {
      // Import the Markdown file dynamically
      import(`@/assets/documentation.md`)
        .then(module => {
          // Use marked to render the Markdown content to HTML
          this.renderedMarkdown = marked(module.default);
        })
        .catch(error => {
          console.error('Error loading Markdown file:', error);
        });
    },
  },
  mounted() {
    // Add a click event listener to the document body
    document.body.addEventListener("click", this.handleDocumentClick);
  },
  beforeMount() {
    // load data
    this.getdata();
    // load markdownas
    this.loadMarkdownFile();
    // SNR
    this.calculateSNR(true);
    this.calculateRMS(true);
  },
  beforeUnmount() {
    // Remove the click event listener when the component is destroyed
    document.body.removeEventListener("click", this.handleDocumentClick);
  }
};
</script>

<style>
#content-labeler {
  align-content: right;
  margin-top: 9vh;
  margin-left: 15vw;
}
.checkbox-all-channels {
  width: 100%;
  height: 1em;
}
#contextdiv {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.checkbox-all {
  display: flex;
  justify-content: flex-end;
  margin-top: 1%;
  margin-right: 2%;
}
.check-span {
  margin-top: 10%;
  display: flex;
  align-items: left;
}

#container {
  display: flex;
  justify-content: flex-end;
  padding-top: 2%;
  padding-right: 2%;
  padding-left: 4%;
}
.content {
  text-align: right;
}
.check {
  margin: 10px;
  text-align: left;
}

.name-chan {
  float: top;
}
.name-chan-cont {
  flex: 1;
}

.container-comments {
  border-radius: 16px;
  padding: 20px;
  background-color: #242424;
  color: #fff;
  font-size: 16px;
  line-height: 1.6;
  max-height: 80vh; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling when content overflows */
  position: relative;
}

.container-comments::-webkit-scrollbar {
  width: 16px;
  /* width of the entire scrollbar */
}

.container-comments::-webkit-scrollbar-thumb {
  background-color: rgb(116, 116, 117);
  /* color of the scroll thumb */
  border-radius: 4px;
  /* roundness of the scroll thumb */
  border: 3px solid transparent;
  /* creates padding around scroll thumb */
  background-clip: content-box;
}

.markdown-content {
  color: #fff;
}

.header-cmnt {
  border: 3px solid #202020;
  margin: 5px;
  border-radius: 16px;
  background-color: #303030;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.icon-clone-cmnt {
  cursor: pointer;
  position: relative;
  top: 0px;
  left: 0px;
  color: #fff;
}

/* Markdown styles for headers and subheaders */
.container-comments h1 {
  border-radius: 8px;
  margin: 8px;
  margin-left: 0px;
  background: #303030;
  padding: 8px;
  font-size: 28px;
  color: white;
}

.container-comments h2 {
  border-radius: 8px;
  margin: 8px;
  margin-left: 8px;
  background: #404040;
  padding: 8px;
  font-size: 24px;
  color: white;
}

.container-comments h3 {
  border-radius: 8px;
  margin: 8px;
  background: #505050;
  margin-left: 16px;
  padding: 8px;
  padding: 5px;
  font-size: 20px;
  color: white;
}

.container-comments h4 {
  font-size: 18px;
  color: white;
}

.container-comments h5 {
  font-size: 16px;
  color: white;
}

.container-comments h6 {
  font-size: 14px;
  color: white;
}

/* Table styles */
.container-comments table {
  width: 80%;
  margin: 20px;
  align-content: center;
  border-collapse: collapse;
  margin-bottom: 20px;
  color: black;
}

.container-comments th,
.container-comments td {
  border: 1px solid #2c2c2c;
  padding: 8px;
}

.container-comments th {
  background-color: #303030;
  color: #fff;
}

textarea {
  width: 900px;
  height: 90px;
  max-height: 90px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #7fa2de5d;
  border-radius: 4px;
  background-color: #7fa0de41;
  font-size: 16px;
  resize: none;
  color: rgb(255, 255, 255);
}
textarea {
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  transition: scrollbar-color 0.9s ease-out;
}
textarea:hover {
  overflow-y: auto;
}
textarea::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

textarea::-webkit-scrollbar-thumb {
  background-color: #7fafde; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid transparent; /* creates padding around scroll thumb */
  background-clip: content-box;
}

.container-name-cnmt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-export-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: #7fa0de1a;
  border-radius: 10px;
}
.modal-export-btns:hover {
  background-color: #7fa8de4a;
}
.modal-export-btns:active {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: 1px solid rgb(255, 255, 255);
}
.modal-seperator-items {
  height: 20px;
}
.modal-icon {
  margin-left: 30%;
}
.modal-editor-icon {
  width: 30%;
}

.header-cmnt {
  flex: 1;
  font-size: 40px;
  color: #ffffff;
}

.titles {
  padding: 1%;
  margin-left: .4vw;
  margin-right: .4vw;
  background-color: lightblue;
  border: 3px solid #2120414a;
  border-radius: 8px;
  cursor: default;
  font-size: 0.9vw;
}

.snr-container {
  padding: 1%;
  margin: 1%;
  background-color: rgb(255, 206, 120);
  border: 3px solid #4137204a;
  border-radius: 8px;
  cursor: default;
  font-size: .9vw;
}

.popup {
  position: fixed;
  padding: 5px;
  color: white;
  background-color: #303030;
  border: 2px solid black;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.copy-icon {
  height: min(2vh, 1vw);
  width: min(2vh, 1vw);
  margin-left: 5px;
  cursor: pointer;
}

.copy-icon:hover { color: rgba(48, 47, 47, 0.929); }

.container-btns {
  color: #ffffff;
  width: 80%;
}

.stats-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input[type="checkbox"] {
  width: 1.4rem;
  height: 1.4rem;
  vertical-align: middle;
  border-radius: 8px;
  border: 4;
  outline: 1;
  flex-grow: 4;
  transition: all 300ms;
  cursor: pointer;
  background: whitesmoke;
}

.channel-snr-container {
  min-width: 4.5vw;
  max-width: 4.5vw;
  font-size: 0.75vw;
  padding: .2vw;
  margin-left: 4px;
  margin-right: 4px;
  background-color: rgb(255, 206, 120);
  border: 1px solid #4137204a;
  border-radius: 8px;

  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.channel-snr-container > * {
  padding: 4px;
  margin: 0;
  text-align: center; /* Center text within the child element */
}

.channel-rms-container {
  min-width: 4.5vw;
  max-width: 4.5vw;
  font-size: 0.75vw;
  padding: .2vw;
  margin-left: 4px;
  margin-right: 4px;
  background-color: rgb(159, 210, 114);
  border: 1px solid #4137204a;
  border-radius: 8px;

  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.channel-rms-container > * {
  padding: 4px;
  margin: 0;
  text-align: center; /* Center text within the child element */
}
</style>
