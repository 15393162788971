import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PingTest from '../components/PingTest.vue';
import UserSelection from '../components/UserSelection.vue';
import RecordingSelection from '../components/RecordingSelection.vue';
import LabelerComponent from '../components/labeler/LabelerComponent.vue';
import mainICA from '../components/ica/mainICA.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/ping',
    name: 'ping',
    component: PingTest,
  },
  {
    path: '/ica/:user/:rec',
    name: 'ica',
    component: mainICA,
    props: true,
  },
  {
    path: '/labeler/:user/:rec',
    name: 'labeler',
    component: LabelerComponent,
    props: true,
  },
  {
    path: '/users',
    name: 'users',
    component: UserSelection,
    props: true,
  },
  {
    path: '/recordings/:user',
    name: 'recordings',
    component: RecordingSelection,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
