<script>
/* eslint-disable */
import { collapsed, toggleSidebar } from "../sidebar/state";
import MenuComponent from "@/components/sidebar/MenuComponent.vue";
import SelectComponent from "@/components/sidebar/SelectComponent.vue";
import LegendComponent from "@/components/sidebar/LegendComponent.vue";
import SwitchComponent from "@/components/sidebar/SwitchComponent.vue";

export default {
  props: [
    "role", "annotationMode", "showStims", "showStepSizes", "showEventAnnots",
    "showNoiseAnnots", "showTaskOnly", "showThreshold", "showPDDetection", "showPDInterval",
    "eventsLabels", "eventsColorMap", "noiseLabels", "noiseColorMap",
    "selectedEventLabel", "selectedNoiseLabel"
  ],
  emits: [
    "apply-sampling",
    "toggle-step",
    "toggle-stims",
    "toggle-annot",
    "toggle-pd-interval",
    "toggle-pd-detection",
    "reset-scale",
    "clear-charts",
    "choice-event-label",
    "choice-noise-label",
    "export",
    "selectedLabeling",
    "toggle-events",
    "open-editor",
    "toggle-thresh",
    "calculate-snr",
    "toggle-task-only",
    "apply-pd",
    "toggle-peaks",
    "toggle-PdModel",
  ],
  components: {
    MenuComponent,
    SelectComponent,
    LegendComponent,
    SwitchComponent,
  },
  data() {
    return {
      pickedScale: "Time",
      minFreq: 0,
      maxFreq: 2,
      PDSensitivty: 8,
      median: true,
      diff: true,
      accel: false,
      accelXvel: false,

      newNoiseLabel: "",

      togglePeaks: true,
      togglePDModel: true,

      checkPeak: false,
    };
  },
  methods: {
    onInputMin(event) {
      if (parseFloat(event.target.value) > this.maxFreq) {
        window.alert(
          "Please enter a minimum frequency less than the maximum : " +
            this.maxFreq
        );
        this.minFreq = 0;
      } else if (parseFloat(event.target.value) < 0) {
        window.alert("Please enter a minimum frequency greater than 0");
        this.minFreq = 0;
      } else {
        this.minFreq = parseFloat(event.target.value);
      }
    },
    onInputMax(event) {
      if (parseFloat(event.target.value) > 12) {
        window.alert("Please enter a maximum frequency less than 12 Hz");
        this.maxFreq = 2;
      } else if (parseFloat(event.target.value) < this.minFreq) {
        window.alert(
          "Please enter a maximum frequency greater than the minimum : " +
            this.minFreq
        );
        this.maxFreq = 2;
      } else {
        this.maxFreq = parseFloat(event.target.value);
      }
    },
    onPDSensitivity(event) {
      this.PDSensitivty = parseFloat(event.target.value);
    },
    onToggleTask() {
      this.$emit("toggle-task-only")
    },
    onChangeEventLabel(option) {
      this.$emit("choice-event-label", option);
    },
    onChangeNoiseLabel(option) {
      this.$emit("choice-noise-label", option);
    },
    addNoiseLabel() {
      if (!this.newNoiseLabel) return;
      if (
        this.noiseLabels
          .map((x) => x.toLowerCase())
          .includes(this.newNoiseLabel.toLowerCase())
      ) {
        alert("Please enter a different Noise Label");
        this.newNoiseLabel = "";
        return;
      }
      this.noiseLabels.push(this.newNoiseLabel);
      this.newNoiseLabel = "";
    },
    setselectedLabeling(option) {
      this.$emit("selectedLabeling", option);
    },
    date() {
      var year = new Date().getFullYear();
      return year;
    },
  },
  setup() {
    return {
      collapsed,
      toggleSidebar,
    };
  },
};
</script>

<template>
  <div class="sidebar">
    <div class="sidebar-container" v-show="!collapsed">
      <!--TOP-->
      <div id="top-sidebar">
        <h1 class="header-txt"> Tools </h1>
        <i class="fas fa-gear fa-xl"></i>
      </div>

      <div class="line-seperator">
        <hr class="rounded" />
      </div>

      <div class="sidebar-menus-container">
        <!--Task Index-->
        <div class="seperator-items"></div>
        <div
          class="comments-btn"
          @click="onToggleTask()"
        >
          <div class="editor-icon" v-if="showTaskOnly">
            <i
              class="fa-solid fa-crop-simple"
              style="--fa-rotate-angle: 45deg"
            ></i>
          </div>
          <div class="editor-icon" v-else>
            <i
              class="fa-solid fa-expand"
              style="--fa-rotate-angle: 45deg"
            ></i>
          </div>
          <div>
            <span class="button-text">{{
              showTaskOnly ? "Task Only" : "Entire Signal"
            }}</span>
          </div>
        </div>
        <div class="seperator-items"></div>

        <!-- Reset Scale -->
        <div
          class="comments-btn"
          @click="$emit('reset-scale')"
          @keydown="$emit('reset-scale')"
        >
          <div class="editor-icon">
            <i class="fa-solid fa-rotate-right"></i>
          </div>
          <div>
            <span class="button-text">Reset Scale</span>
          </div>
        </div>
        <div class="seperator-items"></div>

        <!-- Clear Annotations -->
        <div
          class="comments-btn"
          @click="$emit('clear-charts')"
          @keydown="$emit('clear-charts')"
        >
          <div class="editor-icon">
            <i class="fa-solid fa-wand-magic-sparkles"></i>
          </div>
          <div>
            <span class="button-text">Clear Labels</span>
          </div>
        </div>
        <div class="seperator-items"></div>

        <!--Filter FREQUENCY-->
        <div class="seperator-items"></div>
        <div class="line-seperator">
          <hr class="rounded" />
        </div>
        <MenuComponent :title="'Filter'">
          <template v-slot:content>
            <div class="min-max-container">
              <div class="main-min">
                <div class="span-min-max">
                  <span> min </span>
                </div>
                <div>
                  <input :value="minFreq" type="number" @input="onInputMin" />
                </div>
              </div>
              <div class="main-max">
                <div class="span-min-max">
                  <span> max </span>
                </div>
                <div>
                  <input :value="maxFreq" type="number" @input="onInputMax" />
                </div>
              </div>
            </div>
            <div class="main-check">
              <div class="span-median">
                <span> MED </span>
              </div>
              <div class="checkboxOverride">
                <label for="median-check">
                  <input type="checkbox" id="median-check" v-model="median" />
                </label>
              </div>
            </div>
            <div class="main-check">
              <div class="span-median">
                <span> DIFF </span>
              </div>
              <div class="checkboxOverride">
                <label for="median-check">
                  <input type="checkbox" id="diff-check" v-model="diff" />
                </label>
              </div>
            </div>
            <div class="main-check">
              <div class="span-median">
                <span> ACC </span>
              </div>
              <div class="checkboxOverride">
                <label for="median-check">
                  <input type="checkbox" id="acc-check" v-model="accel" />
                </label>
              </div>
            </div>
            <div class="main-check">
              <div class="span-median">
                <span> AXV </span>
              </div>
              <div class="checkboxOverride">
                <label for="median-check">
                  <input type="checkbox" id="axv-check" v-model="accelXvel" />
                </label>
              </div>
            </div>
            <div class="reset-btn">
              <div
                class="comments-btn"
                @click="
                  $emit(
                    'apply-sampling',
                    this.minFreq,
                    this.maxFreq,
                    this.median,
                    this.diff,
                    this.accel,
                    this.accelXvel
                  )
                "
              >
                <div class="editor-icon">
                  <i class="fa-solid fa-wave-square"></i>
                </div>
                <div>
                  <span>Apply</span>
                </div>
              </div>
            </div>
            <div class="seperator-items"></div>
          </template>
        </MenuComponent>
      </div>

      <div class="line-seperator">
        <hr class="rounded" />
      </div>

      <div class="sidebar-menus-container">
        <!--LABELS-->
        <div id="section-sidebar">
          <p class="header-txt"> Labels </p>
          <i class="fas fa-tag fa-xl"></i>
        </div>

        <!-- SWITCH -->
        <div class="seperator-items"></div>
        <SwitchComponent
          optionLeft="Events"
          optionRight="Noise"
          :selected=annotationMode
          @selectedLabeling="setselectedLabeling"
          v-show="!(this.role === 'viewer')"
        >
        </SwitchComponent>
        <div class="seperator-items"></div>

        <!-- EVENTS -->
        <div class="line-seperator">
          <hr class="rounded" />
        </div>
        <MenuComponent
          :title="'Event Labels'"
          :open="true">
          <template v-slot:content>
            <SelectComponent
              :options="eventsLabels"
              :colormap="eventsColorMap"
              :value="selectedEventLabel"
              @input="onChangeEventLabel($event)"
              v-show="!(this.role === 'viewer')"
            ></SelectComponent>

            <div class="seperator-items"></div>
            <div class="seperator-items"></div>

            <LegendComponent
              :data="eventsColorMap"
              v-show="(this.role === 'viewer')"
            > </LegendComponent>

            <div
              class="comments-btn"
              @click="$emit('toggle-events')"
              @keydown="$emit('toggle-events')"
            >
              <div class="editor-icon" v-if="showEventAnnots">
                <i class="fa-regular fa-eye"></i>
              </div>
              <div class="editor-icon" v-else>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
              <div>
                <span> Events </span>
              </div>
              <div class="editor-icon">
                <i class="fa-solid fa-arrows-left-right-to-line" />
              </div>
            </div>

            <div class="seperator-items"></div>

            <div
              class="comments-btn"
              @click="$emit('toggle-stims')"
              @keydown="$emit('toggle-stims')"
            >
              <div class="editor-icon" v-if="showStims">
                <i class="fa-regular fa-eye"></i>
              </div>
              <div class="editor-icon" v-else>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
              <div>
                <span> Stimilus</span>
              </div>
              <div class="editor-icon">
                <i class="fa-solid fa-grip-lines-vertical"></i>
              </div>
            </div>

            <div class="seperator-items"></div>

            <div
              class="comments-btn"
              @click="$emit('toggle-step')"
              @keydown="$emit('toggle-step')"
            >
              <div class="editor-icon" v-if="showStepSizes">
                <i class="fa-regular fa-eye"></i>
              </div>
              <div class="editor-icon" v-else>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
              <div>
                <span> Step Size</span>
              </div>
            </div>

            <div class="seperator-items"></div>
          </template>
        </MenuComponent>

        <!--Noise Label-->
        <div class="line-seperator">
          <hr class="rounded" />
        </div>

        <MenuComponent
          :title="'Noise Labels'"
          :open="true">
          <template v-slot:content>
            <SelectComponent
              :options="noiseLabels"
              :colormap="noiseColorMap"
              :value="selectedNoiseLabel"
              @input="onChangeNoiseLabel($event)"
              v-show="!(this.role === 'viewer')"
            ></SelectComponent>

            <div class="seperator-items"></div>
            <div class="seperator-items"></div>

            <LegendComponent
              :data="noiseColorMap"
              v-show="(this.role === 'viewer')"
            > </LegendComponent>

            <div
              class="comments-btn"
              @click="
                $emit('toggle-annot')"
              @keydown="$emit('toggle-annot')"
            >
              <div class="editor-icon" v-if="showNoiseAnnots">
                <i class="fa-regular fa-eye"></i>
              </div>
              <div class="editor-icon" v-else>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
              <div>
                <span> Noise</span>
              </div>
              <div class="editor-icon">
                <i class="fa-solid fa-arrows-left-right-to-line" />
              </div>
            </div>
            <div class="seperator-items"></div>

            <div
              class="comments-btn"
              @click="$emit('toggle-thresh')"
              @keydown="$emit('toggle-thresh')"
            >
              <div class="editor-icon" v-if="showThreshold">
                <i class="fa-regular fa-eye"></i>
              </div>
              <div class="editor-icon" v-else>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
              <div>
                <span> Thresholds</span>
              </div>
              <div class="seperator-items"></div>
            </div>
            <div class="seperator-items"></div>
          </template>
        </MenuComponent>
      </div>

      <div class="line-seperator">
        <hr class="rounded" />
      </div>

      <div class="sidebar-menus-container">
        <!--Measures-->
        <div id="section-sidebar">
          <p class="header-txt"> Measures </p>
          <i class="fas fa-ruler fa-xl"></i>
        </div>

        <div class="line-seperator">
          <hr class="rounded" />
        </div>

        <!--PEAK DETECTION-->
        <div v-show="!(this.role === 'viewer')">
          <MenuComponent :title="'Peak Detection'">
            <template v-slot:content>
              <div class="main-min">
                <div class="span-min-max">
                  <span> Sensitivity </span>
                </div>
                <div>
                  <input :value="PDSensitivty" type="number" @input="onPDSensitivity" />
                </div>
              </div>
              <div class="seperator-items"></div>
              <div
                class="comments-btn"
                @click="$emit('apply-pd', PDSensitivty)"
                @keydown="$emit('apply-pd', PDSensitivty)"
              >
                <div class="editor-icon">
                  <i class="fa-brands fa-think-peaks"></i>
                </div>
                <div>
                  <span>Apply PD</span>
                </div>
                <div v-if="checkPeak" class="check-peak">
                  <i class="fa-solid fa-check"></i>
                </div>
              </div>

              <div v-if="checkPeak">
                <div class="seperator-items"></div>

                <div
                  class="comments-btn"
                  @click="$emit('toggle-pd-interval')"
                  @keydown="$emit('toggle-pd-interval')"
                >
                  <div class="editor-icon" v-if="showPDInterval">
                    <i class="fa-regular fa-eye-slash"></i>
                  </div>
                  <div class="editor-icon" v-else>
                    <i class="fa-regular fa-eye"></i>
                  </div>
                  <div>
                    <span> Interval </span>
                  </div>
                  <div class="editor-icon">
                    <i class="fa-solid fa-arrows-left-right-to-line" />
                  </div>
                </div>

                <div class="seperator-items"></div>

                <div
                  class="comments-btn"
                  @click="$emit('toggle-pd-detection')"
                  @keydown="$emit('toggle-pd-detection')"
                >
                  <div class="editor-icon" v-if="showPDDetection">
                    <i class="fa-regular fa-eye-slash"></i>
                  </div>
                  <div class="editor-icon" v-else>
                    <i class="fa-regular fa-eye"></i>
                  </div>
                  <div>
                    <span> Detections </span>
                  </div>
                  <div class="editor-icon">
                    <i class="fa-solid fa-grip-lines-vertical"></i>
                  </div>
                </div>
              </div>
              <div class="seperator-items"></div>
            </template>
          </MenuComponent>
        </div>

        <!--SNR-->
        <!-- <div class="line-seperator">
          <hr class="rounded" />
        </div>
        <MenuComponent :title="'Signal Quality'">
          <template v-slot:content>
            <div
              class="comments-btn"
              @click="$emit('calculate-snr')"
              @keydown="$emit('calculate-snr')"
            >
              <div class="editor-icon">
                <svg
                  width="22px"
                  height="22px"
                  viewBox="0 0 512 512"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#FFFFFF"
                >
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M426.666667,1.42108547e-14 L426.666667,341.333333 L3.55271368e-14,341.333333 L426.666667,1.42108547e-14 Z M384,88.7466667
                           L255.999,191.147 L255.999,298.666 L384,298.666667 L384,88.7466667 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </svg>
              </div>
              <div>
                <span>Calculate SNR</span>
              </div>
            </div>

            <div class="seperator-items"></div>
          </template>
        </MenuComponent> -->

        <!--COMMENTS-->
        <!-- <div v-show="!(this.role === 'viewer')">
          <div class="line-seperator">
            <hr class="rounded" />
          </div>
          <MenuComponent :title="'Comments'">
            <template v-slot:content>
              <div
                class="comments-btn"
                @click="$emit('open-editor')"
                @keydown="$emit('open-editor')"
              >
                <div class="editor-icon">
                  <i class="fa-solid fa-user-pen"></i>
                </div>
                <div>
                  <span>Open Editor</span>
                </div>
              </div>
              <div class="seperator-items"></div>
            </template>
          </MenuComponent>
        </div> -->

        <div class="seperator-footer"></div>
      </div>
      <!--Footer-->
      <!-- <div class="Footer">
        <div class="aavaa-inc">
          <span>AAVAA Inc © {{ date() }}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #32383d;
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0em;
  transition: 0.1s ease;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: max(220px, 14vw);
  overflow-y: auto;
  scrollbar-gutter: overlay;
}

.sidebar::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #7f9fde;
  /* color of the scroll thumb */
  border-radius: 4px;
  /* roundness of the scroll thumb */
  border: 3px solid transparent;
  /* creates padding around scroll thumb */
  background-clip: content-box;
}

.sidebar #top-sidebar {
  margin-top: 7vh;
  height: 4.5em;
  font-family: "Trebuchet MS";
}
.sidebar #section-sidebar {
  margin: 0px;
  height: 7vh;
  font-family: "Trebuchet MS";
}

.line-seperator .rounded {
  border-top: 3px solid #e4f2fd;
}

#addNewlabel {
  margin-top: 20px;
  justify-content: center;
}

#selectLabel {
  width: 160px;
}

#selectScale {
  width: 160px;
}

#radio-span {
  margin-left: 5px;
}

#btn-add-label {
  justify-content: center;
}

.reset-btn {
  margin-top: 10px;
  padding-top: 5px;
}

.btn-apply-model {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

input[type="number"] {
  width: 3em;
  border: 2px solid #7f8ade82;
  border-radius: 15px;
  padding: 2px;
  background-color: #7f98de41;
  color: white;
  padding-left: 8px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.header-txt {
  margin-top: .4vh;
  margin-bottom: .3vh;
  font-size: 18px;
}

.sidebar-menus-container {
  overflow-y: hidden;
  max-height: 80vh;
  padding-left: .3vw;
  padding-right: .3vw;
  padding-top: .2vh;
  padding-bottom: .1vh;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  transition: scrollbar-color 0.9s ease-out;
}

.sidebar-menus-container:hover {
  overflow-y: auto;
}

.comments-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: #7f8fde1a;
  border-radius: 10px;
}

.comments-btn:hover {
  background-color: #7f8ade4a;
}

.button-text {
    font-size: 18px;
  }

.comments-btn:active {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: 1px solid rgb(255, 255, 255);
}

.seperator-items {
  height: .8vh;
}

.editor-icon {
  width: 30%;
}

.seperator-footer {
  height: 50px;
}

.sidebar-menus-container::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

.sidebar-menus-container::-webkit-scrollbar-thumb {
  background-color: #7f9fde;
  /* color of the scroll thumb */
  border-radius: 4px;
  /* roundness of the scroll thumb */
  border: 3px solid transparent;
  /* creates padding around scroll thumb */
  background-clip: content-box;
}

.min-max-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main-max {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-min {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}

.span-min-max {
  margin-right: 5px;
}

.main-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 6px;
}

.span-median {
  margin-right: 10px;
}

input[type="checkbox"] {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.check-peak {
  width: 25%;
  color: #1fa45f;
}
</style>
