<template>
  <div class="container-svg-signal">
    <div class="scale-container">
      <div>
        {{ 2 * Math.round(YScale)}} μV
      </div>
    </div>
    <div>
      <svg :id="'svg-' + channelName" class="div-channel-plot">
        <g class="signal-line" />
        <g class="time-axis" />
        <g class="stims" />
      </svg>
    </div>
    <div class="name-container">
      <div class="channelName">
        {{channelName}}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
/* eslint prefer-template: "off" */

import * as d3 from 'd3';
import $ from 'jquery';

export default {
  name: 'contextICA',
  components: {
  },
  emits : ['draw-rect', 'change-yscale'],
  props: {
    timeseries: Array,
    channelName: String,
    stims: Object,
    sfreq: Number,
  },
  data() {
    return {
      width: 1500,
      height: 75,

      xScale: null,
      yScale: null,

      selection: null,
      path: null,
      mainLine: null,
      brush: null,

      events: ['Blink-Both', 'Blink-Right', 'Blink-Left', 'Saccade'],
      colormap: {'Blink-Both' : '#02A676', 'Blink-Right' : '#FF9933', 'Blink-Left' : '#A6033F', 'Saccade' : '#6958A6'},

      initYScale: 25,
      YScale: 25,
      zoomStep: 0.1,

      showStims: false,

      cleanedData: [],

    };
  },
  methods: {
    draw() {
      this.xScale = d3.scaleLinear().domain([0, this.timeseries.length]).range([0, this.width]);
      this.yScale = d3.scaleLinear().domain([-this.initYScale, this.initYScale]).range([this.height, 0]);

      // Line
      this.mainLine = d3.line()
        .curve(d3.curveLinear)
        .x((d, i) => this.xScale(i))
        .y((d) => this.yScale(d));

      // Selection
      this.selection = d3.select('#svg-' + this.channelName);

      this.selection.attr('width', this.width)
        .attr('height', this.height+5)
        .attr('viewBox', '0 0 ' + this.width + ' ' + this.height);

      this.path = this.drawLine(this.timeseries);

      this.drawStims();
      this.drawtimeScale();
    },
    drawLine(data) {
      const path = this.selection.select('g.signal-line')
        .append('path').datum(data).attr('class', 'line')
        .attr('stroke', 'steelblue')
        .attr('stroke-width', '1.5')
        .attr('fill', 'none')
        .attr('d', this.mainLine);
      
        return path
    },
    drawtimeScale() {
      this.selection.select('g.time-axis')
        .style("font-size", "9px")
        .call(d3.axisBottom(d3.scaleLinear().domain([0, this.timeseries.length / this.sfreq]).range([0, this.width]))
        .tickSize(3).tickFormat(d => (d + "s")));
      
    },
    drawStims() {
      for (const i in this.stims.rightBlink){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Blink-Right'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.rightBlink[i]))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      for (const i in this.stims.leftBlink){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Blink-Left'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.leftBlink[i]))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      for (const i in this.stims.bothBlink){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Blink-Both'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.bothBlink[i]))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      for (const i in this.stims.stepSize){
        this.selection.select('g.stims').append('rect').style('fill', this.colormap['Saccade'])
              .attr('opacity', '0.7')
              .attr('x', '' + this.xScale(this.stims.stepSize[i].index))
              .attr('y', '' + 0)
              .attr('width', '' + 2)
              .attr('height', '' +  this.height);
      };
      this.selection.select('g.stims').style("visibility", function() {
          return this.showStims ? "visible" : "hidden";
      });
    },
    toggleStims() {
      this.selection.select('g.stims').style("visibility", function() {
            this.showStims = !this.showStims;
            return this.showStims ? "visible" : "hidden";
        });
    },
    CTrue() {
      this.yScale.domain([- (1 + this.zoomStep) * this.YScale, (1 + this.zoomStep) * this.YScale]);
      this.path.attr('d', d3.line()
        .curve(d3.curveLinear)
        .x((d, i) => this.xScale(i))
        .y((d) => (this.yScale(d))));
      this.YScale = this.YScale * (1 + this.zoomStep);
    },
    XTrue() {
      this.path.attr('d', d3.line()
        .curve(d3.curveLinear)
        .x((d, i) => this.xScale(i))
        .y((d) => (this.yScale.domain([- (1 - this.zoomStep) * this.YScale, (1 - this.zoomStep) * this.YScale])(d))));
      this.YScale = this.YScale * (1 - this.zoomStep);
    },
    reDraw(data) {
      this.path.remove();
      this.path = this.drawLine(data);
    },
    Export() {
      return this.cleanedData;
    },
  },
  mounted() {
    this.draw();
  }
};
</script>

<style scoped lang="scss">
.container-svg-signal {
  display: flex;
  flex-direction : row;
  align-items: center;
  justify-content: center;
}
.div-channel-plot{
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #B0AEAF;
}
.scale-container {
  border: 1px solid #B0AEAF;
}
.channelName {
  margin-left: 10px;
}
</style>
