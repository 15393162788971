<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open }"
      @click="toggleDropdown"
      @keydown="toggleDropdown"
    >
      <div class="dot" :style="{ 'background-color': colormap[selected] }" />
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="selectOption(option)"
        @keydown="selectOption(option)"
        class="item"
      >
        <div class="dot" :style="{ 'background-color': colormap[option] }" />
        <div class="text">{{ option }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    colormap: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.value || (this.options.length > 0 ? this.options[0] : null),
      open: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
    },
    selectOption(option) {
      this.selected = option;
      this.open = false;
      this.$emit('input', option); // Emit 'input' event to update external value
    },
  },
  watch: {
    value(newValue) {
      this.selectOption(newValue);
    },
  },
};
</script>

<style scoped lang="scss">
.selected {
  display: flex;
  align-items: center;

  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 10px; // Adjust the margin as needed
  }
}

.item {
  display: flex;
  align-items: center;

  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin-right: 10px; // Adjust the margin as needed
  }

  .text {
    flex-grow: 1; // Allow text to take up available space
  }
}
</style>
