<script>
/* eslint-disable */
import { collapsed, toggleSidebar, sidebarWidth } from '@/components/sidebar/state';
import SwitchICA from '@/components/sidebar/SwitchComponent.vue';
import MenuComponent from '@/components/sidebar/MenuComponent.vue';
import SelectComponent from '@/components/sidebar/SelectComponent.vue';
import LegendComponent from '@/components/sidebar/LegendComponent.vue';

export default {
  name: 'SideBarICA',
  props: [''],
  emits: ['setselectedView', 'undo', 'apply-ica', 'choice-ica',
   'inverse-ica', 'toggle-stims', 'export', 'compare'],
  components: {
    SwitchICA,
    MenuComponent,
    SelectComponent,
    LegendComponent
  },
  data() {
    return {
      collapsed: true,
      initComponents: 0,
      nComponents: 0,

      toggleStimsIcon: true,
      toggleCompareIcon: false,
    };
  },
  methods : {
    setselectedView(option) {
      this.$emit('setselectedView', option);
    },
    onChangeLabel(option) {
      this.$emit('choice-ica', option);
    },
    toggleCompare() {
      this.toggleCompareIcon = true;
    },
    onInput(event) {
      if (parseFloat(event.target.value) > this.initComponents) {
        window.alert('Please enter a number of components less than : ' + this.initComponents);
        this.nComponents = this.initComponents;
      } else if (parseFloat(event.target.value) < 0) {
        window.alert('Please enter a number of components greater than : 0');
        this.nComponents = this.initComponents;
      } else {
        this.nComponents = parseFloat(event.target.value);
      }
    },
  },
  setup() {
    return {
      collapsed,
      toggleSidebar,
      sidebarWidth,
    };
  },
};
</script>

<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
      <div class="sidebar-container" v-show="!collapsed">

        <!--TOP-->
        <div id="top-sidebar">
          <h2>Tools</h2>
          <i class="fas fa-gear fa-xl"></i>
        </div>

        <div class="line-seperator">
                <hr class="rounded">
        </div>

        <div class="sidebar-menus-container">

              <SwitchICA
              optionLeft="Signal"
              optionRight="ICs"
              @selectedLabeling="setselectedView"
              />

              <div class="line-seperator">
                  <hr class="rounded">
              </div>

              <div id="undo" >
                  <div class="undo-container">
                        <div class="undo-btn"
                          @click="$emit('inverse-ica', this.nComponents)"
                          @keydown="$emit('inverse-ica')">
                            <div class="editor-icon">
                              <i class="fa-solid fa-hat-wizard"></i>
                            </div>
                            <div>
                              <span>Inverse</span>
                            </div>
                        </div>
                  </div>
              </div>

              <div class="line-seperator">
                  <hr class="rounded">
              </div>

              <!--UNDO-->
              <div id="undo" >
                <div class="undo-container">
                  <div class="undo-btn" @click="$emit('undo')"
                      @keydown="$emit('undo')">
                      <div class="editor-icon">
                        <i class="fa-solid fa-rotate-left"></i>
                      </div>
                      <div>
                          <span>Undo</span>
                      </div>
                  </div>
                </div>
              </div>

              <div class="line-seperator">
                  <hr class="rounded">
              </div>

              <!--COMPARE-->
              <div id="undo" >
                <div class="undo-container">
                  <div class="undo-btn" @click="$emit('compare'); toggleCompareIcon=!toggleCompareIcon"
                      @keydown="$emit('compare')">
                      <div class="editor-icon" v-if="!toggleCompareIcon">
                        <i class="fa-solid fa-circle-radiation"></i>
                        </div>
                        <div class="editor-icon" v-else>
                            <i class="fa-solid fa-broom"></i>
                        </div>
                        <div>
                            <span>{{toggleCompareIcon? "Cleaned" : "Original"}}</span>
                        </div>
                  </div>
                </div>
              </div>

              <!--VIEW-CONTROLS-->
              <div class="line-seperator">
                <hr class="rounded">
              </div>
              <MenuComponent :title="'View Controls'">
                <template v-slot:content>
                    <div class="comments-btn" @click="$emit('toggle-stims'); toggleStimsIcon=!toggleStimsIcon"
                        @keydown="$emit('toggle-stims')">
                        <div class="editor-icon" v-if="toggleStimsIcon">
                            <i class="fa-regular fa-eye"></i>
                        </div>
                        <div class="editor-icon" v-else>
                            <i class="fa-regular fa-eye-slash"></i>
                        </div>
                        <div>
                            <span>{{toggleStimsIcon? "Show" : "Hide"}}</span> <span>Stims</span>
                        </div>
                    </div>

                    <LegendComponent :data="{'Blink-Left' : '#A6033F', 'Blink-Right' : '#FF9933', 'Blink-Both' : '#02A676', 'Saccade' : '#6958A6'}">
                  </LegendComponent>

                </template>
              </MenuComponent>

               <!--APPLY-ICA-->
               <div class="line-seperator">
                <hr class="rounded">
              </div>
              <MenuComponent :title="'ICA Controls'">
                <template v-slot:content>

                  <SelectComponent
                  :options="['FastIca']"
                  :default="'FastIca'"
                  @input="onChangeLabel($event)"
                  ></SelectComponent>

                  <div class="min-max-container">
                    <div class="main-components">
                        <div class="span-min-max">
                          <span> Components </span>
                        </div>
                        <div>
                            <input
                              :value="nComponents"
                              type="number"
                              @input="onInput"
                            >
                        </div>
                    </div>
                  </div>

                  <div class="comments-btn" @click="$emit('apply-ica', this.nComponents)"
                    @keydown="$emit('apply-ica')">
                    <div class="editor-icon">
                      <i class="fa-solid fa-wave-square"></i>
                    </div>
                    <div>
                      <span>Apply</span>
                    </div>
                  </div>


                </template>
              </MenuComponent>

              <!--Export-->
              <div class="line-seperator">
                  <hr class="rounded">
              </div>
              <MenuComponent :title="'Export Clean Signal'">
                  <template v-slot:content>
                    <div class="comments-btn" @click="$emit('export')"
                      @keydown="$emit('export')">
                      <div class="editor-icon">
                        <i class="fa-solid fa-cloud-arrow-up"></i>
                      </div>
                      <div>
                        <span>Export</span>
                      </div>
                    </div>
                  </template>
              </MenuComponent>

              <div class="seperator-footer">
                </div>

        </div>
<!--Footer-->
        <div class="Footer">
          <div  class="aavaa-inc">
            <span>AAVAA Inc © 2022</span>
          </div>
        </div>
    </div>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #32383D;
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);
  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0em;
  transition: 0.1s ease;
  display: flex;
  flex-direction: column;
}

.sidebar #top-sidebar {
  margin-top: 70px;
  height: 4.5em;
  font-family: "Trebuchet MS";
}

.line-seperator.rounded{
  border-top: 2px solid #A1B4C4;
}


.sidebar-menus-container {
  overflow-y: hidden;
  max-height: 80vh;
  padding: 1rem;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  transition: scrollbar-color 0.9s ease-out;
}
.sidebar-menus-container:hover {
  overflow-y: auto;
}

#undo {
  height: 3em;
  width: 100%;
  border: 1px solid rgb(40, 61, 40);
  display: flex;
  justify-content: center;
  align-items: center;
}
.undo-container{
  width: 60%;
  font-weight: bold;
}
.undo-btn {
  height: 1.8em;
  display: flex;
  flex-direction : row;
  align-items: center;
  cursor: pointer;
  background-color: #8f7fde1a;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
.undo-btn:hover {
  background-color: #8f7fde4a;
}
.undo-btn:active {
  box-sizing: border-box;
   -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: 1px solid rgb(255, 255, 255);
}
.comments-btn {
  display: flex;
  flex-direction : row;
  align-items: center;
  cursor: pointer;
  background-color: #8f7fde1a;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 20px;
}
.comments-btn:hover {
  background-color: #8f7fde4a;
}
.comments-btn:active {

   box-sizing: border-box;
   -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  outline: 1px solid rgb(255, 255, 255);
}

.min-max-container {
  display: flex;
  flex-direction : column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.main-components {
  display: flex;
  flex-direction : row;
  align-items: center;
  width: 100%;
}
.span-min-max {
  margin-right: 5px;
  width: 60%;
}

input[type="number"] {
  width: 3em;
  border: 2px solid #8f7fde82;
  border-radius: 15px;
  padding: 2px;
  background-color: #8f7fde41;
  color: white;
  padding-left: 8px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.editor-icon {
  width: 30%;
}

.seperator-footer {
  height: 20px;
}

.Footer {
  height: 2em;
  position: absolute;
  margin-top: 25px;
  width: 100%;
  bottom: 0;
  background-color: #3c3a4b;
}
.aavaa-inc {
  margin-left: 10%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar-menus-container::-webkit-scrollbar {
  width: 14px;               /* width of the entire scrollbar */
}

.sidebar-menus-container::-webkit-scrollbar-thumb {
  background-color: #8f7fde;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
  border: 3px solid transparent;  /* creates padding around scroll thumb */
  background-clip: content-box;
}

</style>
