<template>
  <nav class="navbar navbar-expand-lg fixed-top">
    <div class="navbar-nav">
      <div id="sidebar-btn">
        <span
          class="collapse-icon"
          :class="{ 'rotate-180': !collapsed }"
          @click="toggleSidebar"
          @keydown="toggleSidebar"
        >
          <i class="fa-solid fa-bars fa-xl" />
        </span>
      </div>

      <div class="aavaa-labeler">
        <router-link
          class="nav-link"
          v-bind:to="'/'"
        >AAVAA Labeler</router-link>
      </div>
      <div>
        <router-link
          class="nav-link"
          v-bind:to="'/users/'">Users</router-link>
      </div>
      <div>
        <router-link
          class="nav-link"
          v-bind:to="'/recordings/' + user"
        >Recordings</router-link>
      </div>
      <div>
        <div
          class="nav-link"
          @click="$emit('toggle-help')"
          @keydown="$emit('toggle-help')"
        >Documentation</div>
      </div>
      <div>
        <router-link class="nav-link" v-bind:to="''">License</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
import { collapsed, toggleSidebar} from '@/components/sidebar/state';

export default {
  name: "NavBar",
  props : ['token', 'user', 'username', 'role'],
  emits: [],
  data () {
    return {
        collapsed: true,
    };
  },
  methods : {
  },
  setup() {
      return { collapsed, toggleSidebar }
    }
};

</script>

<style scoped>
.aavaa-labeler {
    margin-left: 70px;
}
.navbar { background:#202529; }
.nav-link , .navbar-brand { color: #f4f4f4; cursor: pointer; }
.nav-link {
     margin-right: 1em !important;
     font-family: "Trebuchet MS";
}
.nav-link:hover { background: #ffffff22; color: #ffffff; }

#sidebar-btn{
    margin-right: 55px;
    margin-left: 12px;
}

.collapse-icon {
    position: absolute;
    padding: 0.5em;
    color: rgb(255, 255, 255);
    transition: 0.2s linear;
    cursor: pointer;
}
.collapse-icon:hover {
    background: #ffffff22;
    border-radius: 50%;
}
.rotate-180 {
    transform: rotate(90deg);
    transition: 0.2s linear;

}
</style>